import { ActionContext } from 'vuex';
import { State } from '../state';
import { IChatPlataformReport } from '@/store/interfaces/Chat';

export default {
  async createChatPlataformReport ({ dispatch }: ActionContext<State, any>, payload: Partial<IChatPlataformReport>) {
    try {
      const { data }: { data: IChatPlataformReport } = await dispatch(
        'axios',
        {
          url: 'chat/item/report/add',
          method: 'POST',
          data: payload
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_CHAT_PLATAFORM_REPORT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },

  async getChatPlataformReportByChatId ({ dispatch }: ActionContext<State, any>, id_chat: number) {
    try {
      const { data }: { data: IChatPlataformReport } = await dispatch(
        'axios',
        {
          url: `chat/item/report/get_by_chat_id/${id_chat}`,
          method: 'GET'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_BY_ID_CHAT_PLATAFORM_REPORT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getAllChatPlataformReportsByChatIdAndPlataformId ({ dispatch }: ActionContext<State, any>, payload: { id_chat_item: number; id_plataform: number }) {
    try {
      const { data }: { data: IChatPlataformReport[] } = await dispatch(
        'axios',
        {
          url: `chat/item/report/get_by_chat_id/${payload.id_chat_item}/${payload.id_plataform}`,
          method: 'GET'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_CHAT_PLATAFORM_REPORTS_BY_CHAT_ID_AND_PLATAFORM_ID', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async deleteChatPlataformReport ({ dispatch }: ActionContext<State, any>, id_chat_plataform_report: number) {
    try {
      const { data }: { data: IChatPlataformReport[] } = await dispatch(
        'axios',
        {
          url: `chat/item/report/delete/${id_chat_plataform_report}`,
          method: 'DELETE'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DELETE_CHAT_PLATAFORM_REPORT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async updateChatPlataformReport ({ dispatch }: ActionContext<State, any>, payload: IChatPlataformReport) {
    try {
      const { data }: { data: IChatPlataformReport[] } = await dispatch(
        'axios',
        {
          url: `chat/item/report/update/${payload.id_chat_plataform_report}`,
          method: 'PUT',
          data: payload
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_CHAT_PLATAFORM_REPORT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  }
}
