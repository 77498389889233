import { IRoute } from '@/store/interfaces/Route'
import { RouteDestination } from '../geofences'
import { Geofence } from '@/store/interfaces/Geofences'
import { IMarker } from '@/store/interfaces/Marker'
import { PlataformPlacesType, IPlaceTypes } from '@/store/interfaces/Plataform'
import ReportItemData from '@/store/packages/ReportItem/ReportItemData'

const state = {
  /** Referencia al componente del mapa, para operaciones como centrarlo u otras relacionadas con leaflet */
  $ref: null as any | null,
  /** Muestra las geocercas seleccionadas de las unidades en el mapa */
  selectedGeofences: [] as Geofence[],
  /** Muestra las rutas de geocercas seleccionadas de las rutas en el mapa */
  selectedRouteGeofences: [] as RouteDestination[],
  /** Marcadores seleccionados en el mapa */
  selectedMarkers: [] as IMarker[],
  /** Rutas de google renderizadas  */
  selectedRoutes: [] as IRoute[],
  /** El id del reporte seleccionado y que tiene el foco */
  curr_id_report_item: null as number | null,
  /** La key del mapa para refresar el mapa */
  mapObjsKey: 1,
  /** Cargando el analisis de datos de las unidades */
  loadingUnitsAnalytics: false,
  loadingUnitsAnalyticsGeofences: false,
  /** Analisis de datos de unidades */
  unitsAnaliticsData: {} as Record<string, {
    pptype: PlataformPlacesType
    reportItem: ReportItemData<unknown, unknown> | null;
    places: IPlaceTypes[]
    currMarkerGeofences: Geofence[]
  }>
}

export type State = typeof state

export default state
