/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReportItem } from '@/store/interfaces/ReportModules';
import store from '@/store/index';
import getGeofencesIdByCoordinates from '@/utils/wialonSDK/getGeofencesIdByCoordinates';
import { RouteDestination } from '@/store/modules/geofences';
import intersection from '@/utils/intersection';
import Driver from '@/store/interfaces/Driver';
import getLocation from '@/utils/wialonSDK/getLocation';
import ReportItemData, { ReportItemProperties } from './ReportItemData';
import { ICommand } from '@/store/interfaces/Command';
import {
  WIALON_SENSORS_TYPES,
  WIALON_VOLTAGE_SENSOR_TYPE
} from '@/store/catalogs/WIALON_SENSORS_TYPES';
import { WSensor } from '@/store/interfaces/WSensor';
import getSecondsDiffFromNow from '@/utils/getSecondsDiffFromNow'
import { formatTime, hourFormat } from '@/utils/datetimeToDate';
import Vue from 'vue';
import { REPORT_ITEM_IGNITION_STATE } from '@/store/catalogs/REPORT_ITEM_IGNITION_STATE';
export default class RIWialon
  extends ReportItemData<any, any>
  implements ReportItem {
  public bateryFlag: string
  public keyUnitMessage: string
  public lastSpeedValue: number
  public timeDiff: number
  public timeStillStopped: number

  constructor (reportItem: ReportItem, properties: ReportItemProperties<any>, options = { initObservers: true }) {
    super(reportItem, properties, options)
    this.bateryFlag = 'V'
    this.keyUnitMessage = ''
    this.lastSpeedValue = 1
    this.timeDiff = 0
    this.timeStillStopped = 0
  }

  getWialonParentResourceId (): number {
    return Number(this.unit_item.$$user_accountId)
  }

  getUnitResorucesIds (): number[] {
    return [...new Set([Number(this.gp_wialon_id), this.getWialonParentResourceId()])]
  }

  getUnitName () {
    return (this.unit_item && this.unit_item?.getName) ? this.unit_item.getName() : this.ri_unit
  }

  public async onUnitDataChanged (): Promise<void> {
    await super.onUnitDataChanged()
    const report = this.getUnitLastMessage()

    if ((!report || !report?.pos?.s) && (this.lastSpeedValue !== 0)) { // No viene reporte cuando esta detenida
      this.lastSpeedValue = 0
      this.timeStillStopped = Math.floor(new Date().getTime() / 1000)
    } else if (report?.pos?.s !== 0 && this.lastSpeedValue === 0) {
      this.lastSpeedValue = 1
      this.timeStillStopped = 0
    }
  }

  unsusbscribeToUnitMessages (): void {
    if (this.keyUnitMessage) {
      this.unit_item.removeListenerById(this.keyUnitMessage)
    }
  }

  susbscribeToUnitMessages () {
    let keyUnitMessage = ''
    try {
      const lastMessage = this.getUnitLastMessage();
      if (lastMessage) {
        this.last_message = lastMessage;
      }

      if (!this.unit_item.getLastMessage) {
        console.log(`Unidad ${this.ri_unit} sin acceso a los ultimos mensaje`);
        this.unit_item.getLastMessage = function () {
          return new Date().getMilliseconds();
        };
      }

      keyUnitMessage = this.unit_item.addListener(
        'changeLastMessage',
        (event: { getData: () => any }) => {
          const messageData = event.getData();
          this.last_message = messageData;
          this.onUnitDataChanged();
        }
      );
    } catch (error) {
      console.error(error);
    }

    setTimeout(() => {
      this.keyUnitMessage = keyUnitMessage
    }, 0)
  }

  getLastMessageTimeInMillisecons (): number {
    return this.last_message.t;
  }

  async getUbication () {
    const lastMessage = this.getUnitLastMessage();

    if (lastMessage && lastMessage.pos) {
      const ubication = await getLocation({
        lon: lastMessage.pos.x,
        lat: lastMessage.pos.y
      });
      return ubication;
    } else {
      return 'SIN UBICACION DISPONIBLE';
    }
  }

  async getCurrPlataformGeofencesIds (message: { y: any; x: any }) {
    return await getGeofencesIdByCoordinates(
      this.gp_wialon_id,
      message.y,
      message.x
    );
  }

  public async isOnZone (message: { y: any; x: any }) {
    const isOnConsoleGeofences = this.isOnZoneConsoleGeofences(message);
    if (isOnConsoleGeofences) return true
    const geofencesIds = await this.getCurrPlataformGeofencesIds(message) // ids de geocercas en este punto
    // @ts-ignore
    const destinations = store?.state?.geofences?.routesDestinationsGeofences?.find((rd) => rd.id_group === this.id_group);

    // @ts-ignore
    const route_destinations: RouteDestination[] = !destinations
      ? []
      : destinations.route_destinations;
    const rd_ids = this.last_data.DESTINATION.route_destinations;
    const geofences = this.last_data.DESTINATION.geofences;

    const routes: RouteDestination[] = intersection(
      route_destinations,
      rd_ids,
      (rd, rdi) => {
        return rd.id_route_destinations === rdi.id_route_destinations;
      }
    );
    const onGeofences = (): boolean => {
      for (let i = 0; i < routes.length; i++) {
        const route = routes[i];
        // Rutas de destino
        for (let j = 0; j < route.rd_geofences.length; j++) {
          const geofence = route.rd_geofences[j];

          for (let l = 0; l < geofencesIds.length; l++) {
            const geofenceId = geofencesIds[l];
            if (geofence.id === geofenceId) {
              return true;
            }
          }
        }
      }
      // Geocercas individuales
      for (let i = 0; i < geofences.length; i++) {
        const geofence = geofences[i];

        for (let j = 0; j < geofencesIds.length; j++) {
          const geofenceId = geofencesIds[j];
          if (geofence.id === geofenceId) {
            return true;
          }
        }
      }
      return false;
    };
    return onGeofences()
  }

  public initLocationObserver () {
    // console.log(this.ri_unit, this.location.keyListener)
    // @ts-ignore
    if (this.location.keyListener) return;
    this.last_message = this.getUnitLastMessage();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const keyOnChangePosition = this.unit_item.addListener(
      'changePosition',
      async (event: { getData: () => any }) => {
        if (!this.location.keyListener) return
        const eventData = event.getData();
        await this.makeLocationRequest(eventData);
      }
    );

    this.location.keyListener = keyOnChangePosition;
    // @ts-ignore
    this.makeLocationRequest(this.last_message.pos);
  }

  public stopLocationObserver () {
    // console.log(this.ri_unit, this.location.keyListener)
    if (!this.location.keyListener) return
    const stopedEvent = this.unit_item.removeListenerById(
      this.location.keyListener
    );
    if (stopedEvent) {
      // console.log(this.ri_unit, stopedEvent)
      this.location.onZone = true;
      this.location.keyListener = '';
    } else {
      store.dispatch('sys/showNotificationMessage', {
        title: `No se puedo desuscribir del evento de ${this.ri_unit} reintente nuevamente`,
        duration: -1,
        color: 'error'
      });
      store.dispatch('sys/playSound', {
        type: 'alert'
      });
    }
  }

  getIcon (): string {
    return this.unit_item.getIconUrl();
  }

  async getIgnitionState (): Promise<REPORT_ITEM_IGNITION_STATE> {
    const state = this.getWialonUnitSensorData(
      WIALON_SENSORS_TYPES.ENGINE_IGNITION_SENSOR
    );
    return state;
  }

  async getLinkCamera () {
    return ''
  }

  async getHistoryVideo () {
    return ''
  }

  getCoordinates (): string {
    const lastMessage = this.getUnitLastMessage();
    if (lastMessage && lastMessage.pos) {
      return `${lastMessage.pos.y},${lastMessage.pos.x}`;
    } else {
      return '';
    }
  }

  speed () {
    const lastMessage = this.getUnitLastMessage();
    // @ts-ignore
    return lastMessage?.pos?.s;
  }

  stoppedTime (useTimeDiff = false): string | number {
    this.timeDiff = getSecondsDiffFromNow(this.timeStillStopped)
    if (useTimeDiff) return this.timeDiff;
    if (this.timeStillStopped === 0) return '0 seg (En movimiento)'
    return `${hourFormat(this.timeStillStopped)} (${formatTime(this.timeDiff)})`
  }

  getGoogleLink (): string {
    const lastMessage = this.getUnitLastMessage();
    // @ts-ignore
    if (lastMessage && lastMessage.pos) {
      // @ts-ignore
      return `http://maps.google.com/?q=${lastMessage.pos.y},${lastMessage.pos.x}`;
    } else {
      return 'UNIDAD SIN ULTIMO MENSAJE DISPONIBLE';
    }
  }

  getCurrentCoordinatesRaw () {
    const lastMessage = this.getUnitLastMessage();
    if (lastMessage && lastMessage.pos) {
      return { x: lastMessage.pos.x, y: lastMessage.pos.y };
    } else {
      return { x: 0, y: 0 };
    }
  }

  getResDrivers (): Driver[] {
    // @ts-ignore
    const resource = store.state?.drivers?.drivers[this.ri_type][this.gp_wialon_id];
    if (!resource) return [];

    // @ts-ignore
    const drivers: Driver[] = (resource[this.ri_wialon_id] || []).map((obj: Driver) => ({ ...obj, item: store.state?.drivers.consoleGroupDriversByGroup[this.id_group].find(drv => drv.item?.drv_custom_properties?.res_driver?.id === obj.id)?.item }));
    return Vue.observable(drivers)
  }

  public getSensors (): Promise<{ name: string; result: string; id: number }[]> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      const sess = wialon.core.Session.getInstance();
      // @ts-ignore
      const flags = wialon.item.Item.dataFlag.base | wialon.item.Unit.dataFlag.sensors | wialon.item.Unit.dataFlag.lastMessage;
      sess.updateDataFlags(
        // load items to current session
        [{ type: 'type', data: 'avl_unit', flags: flags, mode: 1 }], // Items specification
        (code: string) => {
          // updateDataFlags callback
          if (code) {
            // @ts-ignore
            reject(wialon.core.Errors.getErrorText(code));
          } // exit if error code
          const sensorInfo = [];
          const unit = this.unit_item;
          const sens = unit.getSensors(); // get unit's sensors
          for (const i in sens) {
            let result = unit.calculateSensorValue(
              sens[i],
              unit.getLastMessage()
            );
            if (result === -348201.3876) result = 'N/A';
            sensorInfo.push({ name: sens[i].n, result, id: sens[i].id });
          }
          resolve(sensorInfo);
        }
      );
    });
  }

  public getCommandList () {
    const cmds = this.unit_item.getCommandDefinitions(); // get unit's commands
    const cmdsArray = Object.values(cmds);
    // @ts-ignore
    const cmdsFilter = cmdsArray.filter((cmd) => !cmd.n.match(/^__app__.*$/));
    // @ts-ignore
    const cmdsMap: ICommand[] = cmdsFilter.map((c) => ({ id: c.id, name: c.n, payload: c }));
    return cmdsMap;
  }

  public executeCommand (payload: any) {
    return new Promise<void>((resolve, reject) => {
      this.unit_item.remoteCommand(
        payload.n,
        payload.l,
        payload.p,
        0,
        (code: string) => {
          // execute command callback
          if (code) {
            // @ts-ignore
            reject(wialon.core.Errors.getErrorText(code));
          } else {
            resolve();
          }
        }
      );
    });
  }

  public batteryGPSLevel (): number {
    const wsensors_data = this.unit_item.getSensors()
    if (!wsensors_data) return -1
    const sensors: WSensor[] = Object.values(wsensors_data);
    const battery = sensors.filter((ws) =>
      ws.d.toLowerCase().includes(WIALON_VOLTAGE_SENSOR_TYPE.BATTERY_GPS)
    );
    if (battery.length) {
      if (battery[0].t === WIALON_SENSORS_TYPES.VOLTAGE_SENSOR) {
        const value = this.unit_item.calculateSensorValue(
          battery[0],
          this.last_message
        );
        return value;
      } else {
        const value = this?.last_message?.p[battery[0].p];
        return value
      }
    } else {
      return -1;
    }
  }

  public batteryUnitLevel (): number {
    const wsensors_data = this.unit_item.getSensors()
    if (!wsensors_data) return -1
    const sensors: WSensor[] = Object.values(wsensors_data);
    const battery = sensors.filter((ws) =>
      ws.d.toLowerCase().includes(WIALON_VOLTAGE_SENSOR_TYPE.BATTERY_UNIT)
    );
    if (battery.length) {
      if (battery[0].t === WIALON_SENSORS_TYPES.VOLTAGE_SENSOR) {
        const value = this.unit_item.calculateSensorValue(
          battery[0],
          this.last_message
        );
        return value;
      } else {
        const value = this?.last_message?.p[battery[0].p];
        return value
      }
    } else {
      return -1;
    }
  }

  public getWialonUnitSensorData (wst: WIALON_SENSORS_TYPES): any {
    const sensors: WSensor[] = Object.values(this.unit_item.getSensors());
    if (!sensors) return null;
    const sensor = sensors.find((sess) => sess.t === wst);
    if (!sensor) return null;
    const value = this.unit_item.calculateSensorValue(
      sensor,
      this.last_message
    );
    return value;
  }

  public getUnitLastMessage () {
    const message = this.unit_item.getLastMessage() || {};
    if (!message.pos) {
      message.pos = this.unit_item.$$user_position
    }
    return message
  }
}
