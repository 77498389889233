export enum PLATAFORM_TYPE {
    CONSOLA = 1,
    WIALON = 2,
    MAPON = 3,
    TRACKSOLID = 4
}

export const PLATAFORM_TYPE_ITEMS = [
  {
    id: 1,
    name: 'Consola'
  },
  {
    id: 2,
    name: 'Wialon'
  },
  {
    id: 3,
    name: 'Mapon'
  },
  {
    id: 4,
    name: 'Tracksolid'
  }
]

export const PLATAFORM_TYPE_STRINGS = {
  [PLATAFORM_TYPE.CONSOLA]: 'Consola',
  [PLATAFORM_TYPE.WIALON]: 'Wialon',
  [PLATAFORM_TYPE.MAPON]: 'Mapon',
  [PLATAFORM_TYPE.TRACKSOLID]: 'Tracksolid'
}
