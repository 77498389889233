import { ActionContext } from 'vuex';
import { State } from '../state';
import { ReportExecParams, ReportExportResultParams } from '@/store/interfaces/wialon/Reports.interface';

export default {
  async reportExec ({ dispatch }: ActionContext<State, string>, payload: ReportExecParams) {
    try {
      const { data }: { data: any } = await dispatch(
        'sys/axios',
        {
          url: 'plataform/wialon/reports/exec',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'WIALON_API_REPORT_EXEC', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async reportExportResult ({ dispatch }: ActionContext<State, string>, payload: ReportExportResultParams) {
    try {
      const { data }: { data: any } = await dispatch(
        'sys/axios',
        {
          url: 'plataform/wialon/reports/export_result',
          method: 'POST',
          data: payload,
          responseType: 'blob'
        },
        { root: true }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'WIALON_API_REPORT_EXPORT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  }
}
