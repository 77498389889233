import ChatItem from '@/store/packages/Chat/ChatItem';
import { State } from './state';

export default {
  chatsArr (state: State) {
    // Obtén todos los valores del objeto chats
    const chatsArray = Object.values(state.chats);

    // Ordena el array por la propiedad date de cada ChatItem
    return chatsArray.sort((a, b) => {
      // Compara las fechas en orden ascendente
      // Si ambos son null, considerarlos iguales
      if (a.lastMessageTimeMillis === null && b.lastMessageTimeMillis === null) return 0;
      // Si solo uno es null, considerar el otro menor
      if (b.lastMessageTimeMillis === null) return 1;
      if (a.lastMessageTimeMillis === null) return -1;
      // Si ninguno es null, comparar los números
      return b.lastMessageTimeMillis - a.lastMessageTimeMillis;
    });
  },
  isSomeChatUnreaded (state: State, getters: any) {
    return (getters.chatsArr as ChatItem[]).some(chat => chat.unreadedChatMessages)
  },
  isSomeUserMention (state: State, getters: any) {
    return (getters.chatsArr as ChatItem[]).some(chat => chat.isUserMentionInChat)
  },
  currChatItem (state: State) {
    return state.currChat
  },
  objSetUsersConectedIds (state: State) {
    const users_ids = state.chatUsersConected.map(obj => obj.id_user)
    return new Set(users_ids)
  },
  currPopupChatItem (state: State) {
    return state.currPopupChatItem.chatItem
  }
}
