import { ActionContext } from 'vuex';
import { State } from '../state';
import ChatItem from '@/store/packages/Chat/ChatItem';
import { CHAT_TYPE } from '@/store/catalogs/CHAT_TYPE';
import { IChat, IChatBotCase, IChatGroup, IChatMessage, IChatSchemaSimple, IChatUser } from '@/store/interfaces/Chat';
import ChatMessage from '@/store/packages/Chat/ChatMessage';
import { USER_TYPE } from '@/store/catalogs/USER_TYPE';
import { Socket } from 'socket.io-client';
import sleep from '@/utils/sleep';

export default {
  async susbscribeToAllChatEvents ({ dispatch, state, rootState }: ActionContext<State, any>) {
    const ids_chats = Object.keys(state.chats)
    const socketChats: Socket = rootState.sys.socketChats
    if (!ids_chats.length || !socketChats) {
      return
    }
    await socketChats.removeAllListeners()
    await sleep(1000)

    await dispatch('subscribeToSocketChatHandlers')
    try {
      await dispatch(
        'axios',
        {
          url: 'chat/item/rooms/subscribe',
          method: 'POST',
          data: ids_chats
        }
      )
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'SUBSCRIBE_CHAT_ITEM_EVENTS', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async susbscribeToChatEventsById ({ dispatch }: ActionContext<State, any>, id_chat: number) {
    try {
      await dispatch(
        'axios',
        {
          url: 'chat/item/rooms/subscribe',
          method: 'POST',
          data: [id_chat]
        }
      )
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'SUBSCRIBE_CHAT_ITEM_EVENTS', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  getOneToOneChatItemByUserId (context: ActionContext<State, any>, id_user: number) {
    return (context.getters.chatsArr as ChatItem[]).find(obj => obj.id_chat_type === CHAT_TYPE.ONE_TO_ONE && obj.chat_users.some(cu => cu.id_user === id_user))
  },
  setEmptyOneToOneChatItem (context: ActionContext<State, string>, payload: { id_user: string; us_name: string; }) {
    const emptyChatItem = ChatItem.createEmptyChatItem(payload)

    context.commit('SET_CURR_CHAT', emptyChatItem)
  },
  async bindGroupToChat ({ dispatch }: ActionContext<State, any>, { id_chat, id_group }: { id_chat: number; id_group: number }) {
    try {
      const { data }: { data: IChat } = await dispatch(
        'axios',
        {
          url: `chat/item/group/bind/${id_chat}/${id_group}`,
          method: 'PUT'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'BIND_GROUP_TO_CHAT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async unbindGroupToChat ({ dispatch }: ActionContext<State, any>, { id_chat, id_group }: { id_chat: number; id_group: number }) {
    try {
      const { data }: { data: IChat } = await dispatch(
        'axios',
        {
          url: `chat/item/group/unbind/${id_chat}/${id_group}`,
          method: 'PUT'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UNBIND_GROUP_TO_CHAT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async createChatItemByChatMessage ({ dispatch }: ActionContext<State, any>, payload: FormData) {
    try {
      const { data }: { data: IChat } = await dispatch(
        'axios',
        {
          url: 'chat/item/create_by_message',
          method: 'POST',
          data: payload
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_CHAT_ITEM_BY_CHAT_MESSAGE', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async createChatItemGroup ({ dispatch }: ActionContext<State, any>, payload: FormData) {
    try {
      const { data }: { data: IChat } = await dispatch(
        'axios',
        {
          url: 'chat/item/create_group',
          method: 'POST',
          data: payload
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_CHAT_ITEM_GROUP', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async toggleEnableChatBots ({ dispatch }: ActionContext<State, any>, toggle: boolean) {
    try {
      const { data }: { data: { ids_chats: number[]; cht_bot_active: boolean; } } = await dispatch(
        'axios',
        {
          url: `chat/item/update/toggle_enable_bots/${toggle}`,
          method: 'PUT'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'TOGGLE_ENABLE_BOTS', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async updateChatItem ({ dispatch, state }: ActionContext<State, any>, { payload, updateItem }: { payload: Partial<IChat> & { id_chat: number }, updateItem: boolean }) {
    try {
      const { data }: { data: IChat } = await dispatch(
        'axios',
        {
          url: `chat/item/update/${payload.id_chat}`,
          method: 'PUT',
          data: payload
        }
      )

      if (updateItem) {
        Object.assign(state.chats[payload.id_chat], payload)
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_CHAT_ITEM', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async deleteChat ({ dispatch }: ActionContext<State, any>, id_chat: number) {
    try {
      const { data }: { data: IChat } = await dispatch(
        'axios',
        {
          url: `chat/item/delete/${id_chat}`,
          method: 'delete'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DELETE_CHAT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async updateThumnail ({ dispatch }: ActionContext<State, any>, { formdata, id_chat }: { formdata: FormData, id_chat: number }) {
    try {
      const { data }: { data: IChatSchemaSimple } = await dispatch(
        'axios',
        {
          url: `chat/item/change_thumbnail/${id_chat}`,
          method: 'PUT',
          data: formdata
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'THUBMNAIL_UPDATED', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  isEmptyChat ({ state }: ActionContext<State, any>) {
    if (!state.currChat) return false
    return state.currChat.id_chat === -1
  },
  userHaveUnreadedMessages ({ state }: ActionContext<State, any>) {
    return Object.values(state.chats).some(chat => chat.unreadedChatMessages > 0)
  },
  socket_ChatCreated ({ commit, dispatch }: ActionContext<State, any>, data: IChat) {
    commit('ADD_CHAT_ITEM', new ChatItem({ data }))
    dispatch('sys/playSound', { type: 'message' }, { root: true })
  },
  socket_ChatDeleted ({ commit, dispatch, state }: ActionContext<State, any>, id_chat: number) {
    const chat = state.chats[id_chat]
    if (!chat) return

    dispatch('sys/showNotificationMessage', {
      title: `El chat ${chat.chatName} a sido eliminado`,
      color: 'warning',
      duration: 5000
    }, { root: true });

    commit('REMOVE_CHAT', id_chat)
    commit('SET_CURR_CHAT', null)
  },
  socket_ChatUpdated ({ state }: ActionContext<State, any>, data: IChatSchemaSimple) {
    const chat = state.chats[data.id_chat]
    if (!chat) return

    chat.rehidratateDataPartial(data)
  },
  socket_ChatUserAdded ({ state }: ActionContext<State, any>, { id_chat, chat_users, message }: { id_chat: number; chat_users: IChatUser[]; message: IChatMessage | null }) {
    const chat = state.chats[id_chat]
    if (!chat) return

    chat.addChatUsersByIChatUsers(chat_users)

    if (message) {
      chat.addChatMessage(new ChatMessage({ data: message, chatItem: state.chats[message.id_chat_item] }))
    }
  },
  socket_ChatUsersUpdated ({ state }: ActionContext<State, any>, { id_chat, chat_users }: { id_chat: number; chat_users: IChatUser[] }) {
    const chat = state.chats[id_chat]
    if (!chat) return

    chat.updateChatUsersByIChatUsers(chat_users)
  },
  socket_ChatThumbnailUpdated ({ state }: ActionContext<State, any>, { chat, message }: { chat: IChatSchemaSimple; message: IChatMessage }) {
    const chatItem = state.chats[chat.id_chat]
    if (!chatItem) return

    chatItem.rehidratateDataPartial(chat)
    chatItem.addChatMessage(new ChatMessage({ data: message, chatItem }))
  },
  socket_ChatUsersRemoved ({ state }: ActionContext<State, any>, { id_chat, chat_users, message }: { id_chat: number; chat_users: IChatUser[]; message: IChatMessage | null }) {
    const chat = state.chats[id_chat]
    if (!chat) return

    chat.updateChatUsersByIChatUsers(chat_users)
    if (message) {
      chat.addChatMessage(new ChatMessage({ data: message, chatItem: state.chats[message.id_chat_item] }))
    }
  },
  socket_ChatGroupBinded ({ state }: ActionContext<State, any>, data: IChatGroup) {
    const chat = state.chats[data.id_chat_item]
    if (!chat) return
    chat.chat_groups.push(data)
  },
  socket_ChatGroupUnBinded ({ state }: ActionContext<State, any>, data: IChatGroup) {
    const chat = state.chats[data.id_chat_item]
    if (!chat) return

    const index = chat.chat_groups.findIndex(chat_group => chat_group.id_chat_group_binded === data.id_chat_group_binded)
    if (index === -1) return

    chat.chat_groups.splice(index, 1)
  },
  socket_chatBotUnknownQuestions ({ state, dispatch, rootGetters }: ActionContext<State, any>, data: { id_chat: number; id_chat_message: number; chm_message: string; chat_bot_case: IChatBotCase | null }) {
    if (!rootGetters['auth/isRol'](USER_TYPE.OPERACIONES, USER_TYPE.MONITOREO, USER_TYPE.ADMINISTRADOR)) return

    const chat = state.chats[data.id_chat]
    if (!chat) return

    dispatch('sys/addLogWithError', { title: 'CHAT_BOT_UNKNOWN_QUESTIONS', color: 'warning', message: data.chm_message, error: data }, { root: true })

    let title = `ISA no ha podido satisfacer la consulta en el chat ${chat.chatName}`
    if (data.chat_bot_case) { title = `Caso detectado: ${data.chat_bot_case.cbc_case_name} en el chat ${chat.chatName}` }

    // Si el caso no existe o si el caso existe y se especifico que se alertara, se muestra una notificacion
    if (!data.chat_bot_case || data.chat_bot_case?.cbc_alert === true) {
      dispatch('sys/showNotificationMessage', {
        title,
        color: 'warning',
        duration: -1,
        data: {
          btnText: 'Ir al chat',
          action: 'chat/forceOpenChat',
          payload: {
            chat,
            id_chat_message: data.id_chat_message
          }
        }
      }, { root: true });
      dispatch('sys/showNavigatorMessage', {
        title,
        icon: chat.thumbnailUrl
      }, { root: true })
    }

    // Si el caso no existe o si el caso existe y se especifico que no se usara una alerta fuerte, se reproduce un sonido de alerta
    // Sino, se reproduce un sonido de error
    if (!data.chat_bot_case || (data.chat_bot_case?.cbc_alert === true && data.chat_bot_case?.cbc_alert_loud === false)) {
      dispatch('sys/playSound', { type: 'alert' }, { root: true })
    } else if (!data.chat_bot_case || (data.chat_bot_case?.cbc_alert === true && data.chat_bot_case?.cbc_alert_loud === true)) {
      dispatch('sys/playSound', { type: 'error' }, { root: true })
    }
  },
  socket_chatBotError ({ state, dispatch, rootGetters }: ActionContext<State, any>, data: { id_chat: number; payload: any; }) {
    if (!rootGetters['auth/isRol'](USER_TYPE.OPERACIONES, USER_TYPE.MONITOREO, USER_TYPE.ADMINISTRADOR)) return

    const chat = state.chats[data.id_chat]
    if (!chat) return

    dispatch('sys/addLogWithError', { title: 'CHAT_BOT_ERROR', color: 'error', message: '', error: data.payload }, { root: true })

    dispatch('sys/showNotificationMessage', {
      title: `ISA a tenido un error al intentar responder en ${chat.chatName}, revise los logs de la consola`,
      color: 'error',
      duration: -1,
      data: {
        btnText: 'Ir al chat',
        action: 'chat/forceOpenChat',
        payload: {
          chat
        }
      }
    }, { root: true });

    dispatch('sys/showNavigatorMessage', {
      title: `A ocurrido un error en ${chat.chatName}`,
      icon: chat.thumbnailUrl
    }, { root: true })

    dispatch('sys/playSound', { type: 'alert' }, { root: true })
  },
  socket_chatsToggleEnableBot ({ state }: ActionContext<State, any>, { cht_bot_active, ids_chats }: { ids_chats: number[]; cht_bot_active: boolean; }) {
    ids_chats.forEach(id_chat => {
      const chat = state.chats[id_chat]
      if (!chat) return
      chat.updateProperty('cht_bot_active', cht_bot_active)
    })
  },
  socket_chatBotUnknownDestinations ({ state, dispatch, rootGetters }: ActionContext<State, any>, data: { id_chat_item: number; payload: any; }) {
    if (!rootGetters['auth/isRol'](USER_TYPE.OPERACIONES, USER_TYPE.MONITOREO, USER_TYPE.ADMINISTRADOR)) return

    const chat = state.chats[data.id_chat_item]
    if (!chat) return

    dispatch('sys/addLogWithError', {
      title: 'CHAT_BOT_UNKNOWN_DESTINATIONS',
      color: 'warning',
      message: '',
      error: {
        chat: {
          id: chat.id_chat,
          name: chat.chatName
        },
        payload: data.payload
      }
    }, { root: true })

    dispatch('sys/showNotificationMessage', {
      title: `ISA no ha podido establecer los destinos del chat ${chat.chatName}`,
      color: 'warning',
      duration: -1,
      data: {
        btnText: 'Ir al chat',
        action: 'chat/forceOpenChat',
        payload: {
          chat
        }
      }
    }, { root: true });

    dispatch('sys/playSound', { type: 'alert' }, { root: true })
  }
};
