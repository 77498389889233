/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable prefer-rest-params */
export default function (func: CallableFunction, wait: number, immediate: boolean) {
  let timeout:number | null

  return function executedFunction () {
    // @ts-ignore
    const context = this
    const args = arguments

    const later = function () {
      timeout = null
      // @ts-ignore
      if (!immediate) func.apply(context, args)
    }

    const callNow = immediate && !timeout
    // @ts-ignore

    clearTimeout(timeout)
    // @ts-ignore
    timeout = setTimeout(later, wait)
    // @ts-ignore

    if (callNow) func.apply(context, args)
  }
}
