import getAVLResourceGeofences from '@/utils/wialonSDK/getAVLResourceGeofences'
import { ActionContext } from 'vuex'
import { Geofence, ICGeofence, RouteDestination } from '@/store/interfaces/Geofences'
import { ReportLayout } from '@/store/interfaces/ReportModules'
import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE'
import { serializeError } from 'serialize-error'
import ConsoleGroup from '@/store/interfaces/ConsoleGroup'
import { Socket } from 'socket.io-client'
import { GeofencesState } from './state'

export default {
  init (context: ActionContext<GeofencesState, string>) {
    // @ts-ignore
    const console_groups: ConsoleGroup[] = context.rootState.console.consoleGroups

    // @ts-ignore
    const socket: Socket = context.rootState.sys.socket
    socket.on('geofence_console_created', data => context.dispatch('socket_geofenceConsoleCreated', data))
    socket.on('geofence_console_updated', data => context.dispatch('socket_geofenceConsoleUpdated', data))
    socket.on('geofence_console_deleted', data => context.dispatch('socket_geofenceConsoleDeleted', data))
    socket.on('geofence_console_created_many', data => context.dispatch('socket_geofenceConsoleCreatedMany', data))

    for (let i = 0; i < console_groups.length; i++) {
      const console_group = console_groups[i]
      socket.on(`geofence_route_destination_created_${console_group.id_group}`, data => context.dispatch('socket_geofencesRouteDestinationCreated', data))
      socket.on(`geofence_route_destination_edit_${console_group.id_group}`, data => context.dispatch('socket_geofenceRouteDestinationEdit', data))
      socket.on(`geofence_route_destination_deleted_${console_group.id_group}`, data => context.dispatch('socket_geofenceRuteDestinationDeleted', data))
    }
  },
  socket_geofenceConsoleCreatedMany ({ state, commit }: ActionContext<GeofencesState, string>, geofences: ICGeofence[]) {
    for (let i = 0; i < geofences.length; i++) {
      const geofence = geofences[i];
      const index = state.geofences.findIndex(rG => rG.id === geofence.id_group && rG.pType === PLATAFORM_TYPE.CONSOLA)
      const geoFormated: Geofence = {
        id: geofence.id_geofence,
        n: geofence.geo_name,
        d: geofence.geo_description,
        // @ts-ignore
        c: geofence.geo_color,
        // @ts-ignore
        geo_payload: geofence.geo_payload,
        pType: PLATAFORM_TYPE.CONSOLA,
        resourceId: geofence.id_group
      }
      if (index !== -1) {
        // @ts-ignore
        state.geofences[index].auxGeofences.push(geoFormated)
        // @ts-ignore
        state.geofences[index].geofences.push(geoFormated)
      } else {
        const rGeofences = {
          id: geofence.id_group,
          name: '',
          geofences: [geoFormated],
          auxGeofences: [geoFormated],
          inputSearch: '',
          pType: PLATAFORM_TYPE.CONSOLA
        }
        commit('PUSH_GEOFENCES', rGeofences)
      }
    }
  },
  socket_geofenceConsoleCreated ({ state, commit }: ActionContext<GeofencesState, string>, geofence: ICGeofence) {
    const index = state.geofences.findIndex(rG => rG.id === geofence.id_group && rG.pType === PLATAFORM_TYPE.CONSOLA)
    const geoFormated: Geofence = {
      id: geofence.id_geofence,
      n: geofence.geo_name,
      d: geofence.geo_description,
      // @ts-ignore
      c: geofence.geo_color,
      // @ts-ignore
      geo_payload: geofence.geo_payload,
      pType: PLATAFORM_TYPE.CONSOLA,
      resourceId: geofence.id_group
    }
    if (index !== -1) {
      // @ts-ignore
      state.geofences[index].auxGeofences.push(geoFormated)
      // @ts-ignore
      state.geofences[index].geofences.push(geoFormated)
    } else {
      const rGeofences = {
        id: geofence.id_group,
        name: '',
        geofences: [geoFormated],
        auxGeofences: [geoFormated],
        inputSearch: '',
        pType: PLATAFORM_TYPE.CONSOLA
      }
      commit('PUSH_GEOFENCES', rGeofences)
    }
  },
  socket_geofenceConsoleUpdated ({ state, commit }: ActionContext<GeofencesState, string>, geofence: ICGeofence) {
    const index = state.geofences.findIndex(rG => rG.id === geofence.id_group && rG.pType === PLATAFORM_TYPE.CONSOLA)
    if (index === -1) return
    const res_geofences = state.geofences[index]
    const geoFormated: Geofence = {
      id: geofence.id_geofence,
      n: geofence.geo_name,
      d: geofence.geo_description,
      // @ts-ignore
      c: geofence.geo_color,
      // @ts-ignore
      geo_payload: geofence.geo_payload,
      pType: PLATAFORM_TYPE.CONSOLA,
      resourceId: geofence.id_group
    }
    const geofence_index = res_geofences.auxGeofences.findIndex(g => g.id === geofence.id_geofence)
    commit('UPDATE_CONSOLE_GEOFENCE', { geofence: geoFormated, geofence_index, index })
  },
  socket_geofenceConsoleDeleted ({ state }: ActionContext<GeofencesState, string>, geofence: ICGeofence) {
    const index = state.geofences.findIndex(rG => rG.id === geofence.id_group && rG.pType === PLATAFORM_TYPE.CONSOLA)
    if (index !== -1) {
      const res_geofences = state.geofences[index]
      const geofence_index = res_geofences.auxGeofences.findIndex(g => g.id === geofence.id_geofence)
      // @ts-ignore
      res_geofences.auxGeofences.splice(geofence_index, 1)
      // @ts-ignore
      res_geofences.geofences.splice(geofence_index, 1)
    }
  },
  async createConsoleGeofence ({ dispatch, commit }: ActionContext<GeofencesState, string>, payload: any): Promise<void> {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'geofences/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'GEOFENCE_CREATE', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async createConsoleGeofenceMany ({ dispatch, commit }: ActionContext<GeofencesState, string>, payload: any): Promise<void> {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'geofences/create/many',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'GEOFENCE_CREATE_MANY', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async updateConsoleGeofence ({ dispatch, commit }: ActionContext<GeofencesState, string>, payload: any): Promise<void> {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'geofences/update',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'GEOFENCE_UPDATE', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async deleteConsoleGeofence ({ dispatch, commit }: ActionContext<GeofencesState, string>, payload: any): Promise<void> {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'geofences/delete',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'GEOFENCE_DELETE', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async reloadConsoleGeofences ({ dispatch, commit }: ActionContext<GeofencesState, string>, data: any): Promise<void> {
    try {
      let geofences = null
      if (data) {
        geofences = data
      } else {
        const { data } = await dispatch(
          'sys/axios',
          {
            url: 'geofences/get/all',
            method: 'GET'
          },
          { root: true }
        )
        geofences = data
      }
      // @ts-ignore
      const consoleGeofences = geofences.reduce((curr, geofence) => {
        // @ts-ignore
        const rGeofences = curr.find(rg => rg.id === geofence.id_group)
        // @ts-ignore
        const geoFormated: Geofence = {
          id: geofence.id_geofence,
          n: geofence.geo_name,
          d: geofence.geo_description,
          c: geofence.geo_color,
          geo_payload: geofence.geo_payload,
          pType: PLATAFORM_TYPE.CONSOLA,
          resourceId: geofence.id_group
        }
        if (rGeofences) {
          rGeofences.auxGeofences.push(geoFormated)
          rGeofences.geofences.push(geoFormated)
        } else {
          const rGeofences = {
            id: geofence.id_group,
            name: geofence.gp_name,
            geofences: [geoFormated],
            auxGeofences: [geoFormated],
            inputSearch: '',
            pType: PLATAFORM_TYPE.CONSOLA
          }
          curr.push(rGeofences)
        }

        return curr
      }, [])

      consoleGeofences.forEach((rg: any) => commit('PUSH_GEOFENCES', rg));
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'RELOAD_CONSOLE_GEOFENCES', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async reloadGeofences (context: ActionContext<GeofencesState, string>, id_group: number) {
    // @ts-ignore
    const console_groups: Array<ConsoleGroup> = context.rootState.console.consoleGroups
    const group = console_groups.find(g => g.id_group === id_group)

    if (!group) {
      await context.dispatch('sys/showNotificationMessage', {
        title: 'No se encontro el grupo',
        color: 'error',
        duration: -1
      }, { root: true });

      return
    }

    try {
      await context.dispatch('sys/showNotificationMessage', {
        title: 'Recargando geocercas...',
        color: 'info',
        duration: -1
      }, { root: true });

      switch (group.id_plataform) {
        case PLATAFORM_TYPE.WIALON: {
          await context.dispatch('wialon/reloadGeofences', { gp_wialon_id: group.gp_wialon_id }, { root: true })
          break;
        }

        case PLATAFORM_TYPE.MAPON: {
          await context.dispatch('mapon/getGeofences', {}, { root: true })
          break;
        }
        default:
          await context.dispatch('sys/showNotificationMessage', {
            title: 'Recurso desconocido',
            color: 'warning',
            duration: -1
          }, { root: true });
          break;
      }

      await context.dispatch('sys/cleanNotifications', {}, { root: true });

      await context.dispatch('sys/showNotificationMessage', {
        title: 'Geocercas cargadas',
        color: 'success',
        duration: 10000
      }, { root: true });
    } catch (error) {
      await context.dispatch('sys/showNotificationMessage', {
        title: 'A ocurrido un error al cargar las geocercas...',
        color: 'error',
        duration: -1
      }, { root: true });
      console.error(error)
      context.commit('sys/ADD_LOG', { title: 'GEOFENCES_RELOAD_ERROR', color: 'error', message: error, payload: serializeError(error) }, { root: true })
    }
  },
  async getAllGeofences (context: ActionContext<GeofencesState, string>): Promise<void> {
    const geofences = await getAVLResourceGeofences()
    context.commit('SET_GEOFENCES', geofences)
  },
  async getRoutesDestinations (context: ActionContext<GeofencesState, string>): Promise<void> {
    const { data } = await context.dispatch(
      'sys/axios',
      {
        url: 'get_routes_destinations',
        method: 'post'
      },
      { root: true }
    )
    context.commit('SET_ROUTES_DESTINATIONS', data)
  },
  setInputValue (context: ActionContext<GeofencesState, string>, val: { pType: PLATAFORM_TYPE, wialon_id: string, value: string }): void {
    if (val.pType === PLATAFORM_TYPE.WIALON) {
      const resource_index = context.state.geofences.findIndex(resource => resource.id === parseInt(val.wialon_id))
      if (resource_index === -1) return
      context.commit('SET_INPUT_VALUE', {
        resource_index,
        value: val.value
      })
      context.dispatch('filtlerGeofences', { resource_index, value: val.value })
    } else if (val.pType === PLATAFORM_TYPE.MAPON) {
      const resource_index = context.state.geofences.findIndex(resource => resource.pType === val.pType)
      if (resource_index === -1) return
      context.commit('SET_INPUT_VALUE', {
        resource_index,
        value: val.value
      })
      context.dispatch('filtlerGeofences', { resource_index, value: val.value })
    }
  },
  filtlerGeofences (context: ActionContext<GeofencesState, string>, val: { resource_index: number, value: string }): void {
    const searchString = val.value.toLowerCase()
    if (searchString) {
      const filteredGeofences = context.state.geofences[val.resource_index].geofences.filter(geofence => {
        return (
          geofence.n.toLowerCase().includes(searchString)
        )
      })
      context.commit('SET_AUX_GEOFENCES', { resource_index: val.resource_index, geofences: filteredGeofences })
    } else {
      context.commit('SET_AUX_GEOFENCES', { resource_index: val.resource_index })
    }
  },
  createGeofenceInPlataform (context: ActionContext<GeofencesState, string>, payload: { plataform: PLATAFORM_TYPE[], geofence: ICGeofence }) {
    // ---
  },
  getGeofencesAndRouteGeofencesByGroup (context: ActionContext<GeofencesState, string>, { pType, ...params }: { pType: PLATAFORM_TYPE, id_wialon?: string, id_group?: number }) {
    const geofences: Geofence[] = context.getters.geofencesItems(pType, params)
    const routeDestinations: RouteDestination[] = context.getters.routesItems(pType, params)

    return { geofences, routeDestinations }
  },
  socket_geofencesRouteDestinationCreated (context: ActionContext<GeofencesState, string>, routeDestination: RouteDestination & { id_group: number }): void {
    const route_destination_index = context.state.routesDestinationsGeofences.findIndex(rDestination => rDestination.id_group === routeDestination.id_group)
    context.commit('ADD_ROUTE_DESTINATION', {
      route_destination_index,
      route_destination: routeDestination
    })
  },
  socket_geofenceRouteDestinationEdit (context: ActionContext<GeofencesState, string>, routeDestination: RouteDestination & { id_group: number }): void {
    const group_index = context.state.routesDestinationsGeofences.findIndex(rdg => rdg.id_group === routeDestination.id_group)
    const route_destinations_index = context.state.routesDestinationsGeofences[group_index].route_destinations.findIndex(rd => rd.id_route_destinations === routeDestination.id_route_destinations)

    context.commit('UPDATE_ROUTE_DESTINATION', {
      route_destinations_index,
      group_index,
      route_destination: routeDestination
    })

    // @ts-ignore
    const report_layouts: ReportLayout[] = context.rootState.reports.reports.filter(report_layout => report_layout.unitData.id_group === routeDestination.id_group)

    for (let i = 0; i < report_layouts.length; i++) {
      const report_items = report_layouts[i].unitData.ri_items

      for (let j = 0; j < report_items.length; j++) {
        const report_item = report_items[j]

        const route_destinations = report_item.last_data.DESTINATION.route_destinations

        for (let k = 0; k < route_destinations.length; k++) {
          const route_destination = route_destinations[k]

          if (route_destination.id_route_destinations === routeDestination.id_route_destinations && report_item.location.keyListener) {
            report_item.makeLocationRequest()
            break
          }
        }
      }
    }
  },
  socket_geofenceRuteDestinationDeleted (context: ActionContext<GeofencesState, string>, routeDPayload: { id_route_destinations: number, id_group: number }): void {
    const group_index = context.state.routesDestinationsGeofences.findIndex(rdg => rdg.id_group === routeDPayload.id_group)
    const route_destinations_index = context.state.routesDestinationsGeofences[group_index].route_destinations.findIndex(rd => rd.id_route_destinations === routeDPayload.id_route_destinations)

    context.commit('REMOVE_ROUTE_DESTINATION', {
      group_index,
      route_destinations_index
    })

    // @ts-ignore
    const report_layouts: ReportLayout[] = context.rootState.reports.reports.filter(report_layout => report_layout.unitData.id_group === routeDPayload.id_group)

    for (let i = 0; i < report_layouts.length; i++) {
      const report_items = report_layouts[i].unitData.ri_items

      for (let j = 0; j < report_items.length; j++) {
        const report_item = report_items[j]

        const route_destinations = report_item.last_data.DESTINATION.route_destinations

        for (let k = 0; k < route_destinations.length; k++) {
          const route_destination = route_destinations[k]

          if (route_destination.id_route_destinations === routeDPayload.id_route_destinations) {
            // @ts-ignore
            const routes = report_item.last_data.DESTINATION.route_destinations.filter(rd => rd.id_route_destinations !== routeDPayload.id_route_destinations)
            report_item.last_data.DESTINATION.route_destinations = routes
            if (report_item.location.keyListener) {
              report_item.makeLocationRequest()
              break
            }
          }
        }
      }
    }
  }
}
