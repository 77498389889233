/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import AssingData from '../../utils/AssingData'
import { IParameters } from '../../interfaces/parameters.interface'
import { Optional } from '../../interfaces/utils.interface'
import { isEqual } from 'lodash'
import Action from './Action'
import {
  Ambienttemp,
  Ignition,
  ApplicationField,
  AvgFuelConsumption,
  BatteryVoltage,
  Can,
  Connected,
  Device,
  Drivers,
  Fuel,
  Fuel_Tank,
  InObjects,
  IoDin,
  MovementState,
  Reefer,
  Relay,
  State,
  SupplyVoltage,
  Tachograph,
  Temperature,
  Unit,
  Weights,
  BatteryLevelPercentage,
  Driver
} from '../../interfaces/unit.interface'
import { ActionConstructor, IAction } from '../../interfaces/actions.interface'
import MaponSDK from '../..'
import { Route } from '../../interfaces/Route.interface'

export type IListUnitParams = Optional<
  Pick<IParameters, 'include' | 'car_number' | 'empty_box_id' | 'unit_id'>,
  'unit_id' | 'include' | 'empty_box_id' | 'car_number'
> & {
  include?: [
    | 'in_objects'
    | 'io_din'
    | 'fuel'
    | 'fuel_tank'
    | 'can'
    | 'reefer'
    | 'drivers'
    | 'temperature'
    | 'ambienttemp'
    | 'device'
    | 'supply_voltage'
    | 'battery_voltage'
    | 'battery_level_percentage'
    | 'relays'
    | 'weights'
    | 'ignition'
    | 'tachograph'
    | 'altitude'];
};

export type IRouteListParameters = Optional<
  Pick<IParameters, 'key' | 'from' | 'till' | 'unit_id' | 'empty_box_id' | 'driver_id' | 'route_types' | 'split_routes_on_border_crossing' | 'include'>,
  'empty_box_id' | 'driver_id' | 'route_types' | 'split_routes_on_border_crossing' | 'include' | 'key'
> & {
  include?: [
    "polyline" | undefined,
    "speed" | undefined,
    "decoded_route" | undefined,
    "driver_id" | undefined,
    "countries" | undefined,
    "can" | undefined,
    "route_details" | undefined,
    "structured_addresses" | undefined,
    "route_fields" | undefined,
    "route_fields" | undefined,
  ];
};

@AssingData()
export default class AUnit extends Action<Unit> implements IAction, Unit {
  public unit_id!: number;
  public box_id!: number;
  public company_id!: number;
  public country_code!: string;
  public label!: string;
  public number!: string;
  public shortcut!: string;
  public vehicle_title?: null | undefined;
  public car_reg_certificate!: string;
  public vin!: string;
  public type!: string;
  public icon?: null | undefined;
  public lat!: number;
  public lng!: number;
  public direction!: number;
  public speed?: number | undefined;
  public mileage!: number;
  public last_update!: string;
  public ignition_total_time!: number;
  public state!: State;
  public ignition?: Ignition | undefined;
  public movement_state!: MovementState;
  public fuel_type!: string;
  public avg_fuel_consumption!: AvgFuelConsumption;
  public created_at!: string;
  public supply_voltage?: SupplyVoltage | undefined;
  public battery_voltage?: BatteryVoltage | undefined;
  public battery_level_percentage?: BatteryLevelPercentage | undefined;
  public device?: Device | undefined;
  public io_din?: IoDin[] | undefined;
  public connected?: Connected | undefined;
  public fuel?: Fuel[] | undefined;
  public fuel_tank?: Fuel_Tank | undefined;
  public in_objects?: InObjects | undefined;
  public temperature?: Temperature | undefined;
  public ambienttemp?: Ambienttemp | undefined;
  public can?: Can | undefined;
  public weights?: Weights | undefined;
  public reefer?: Reefer | undefined;
  public drivers?: Drivers | undefined;
  public relays?: Relay[] | undefined;
  public application_fields?: ApplicationField[] | undefined;
  public tachograph?: Tachograph | undefined;

  public subscribedEventsCount = 0;
  public static unit_ids: number[] = [];

  // ========================== Static methods =============================================

  public static async observeMessages() {
    try {
      const unit_ids = MaponSDK.actions.AUnit.unit_ids
      // @ts-ignore
      const units_data = await MaponSDK.actions.AUnit.list({ include: ['drivers', 'ignition'] }, { raw: true })
      // Evitar el error si la unidad ya no esta disponible
      // const units_data = await MaponSDK.actions.AUnit.list({ unit_id: unit_ids, include: ['drivers', 'ignition'] }, { raw: true })
      for (let i = 0; i < units_data.length; i++) {
        const unit_data = units_data[i]
        MaponSDK.event.emit(MaponSDK.actions.AUnit.getChangeEventNameUnit(unit_data.unit_id), unit_data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  public static susbscribeUnitToData(unit_id: number) {
    if (!AUnit.unit_ids.includes(unit_id)) {
      AUnit.unit_ids.push(unit_id)
    }
  }

  public static unsusbscribeUnitToData(unit_id: number) {
    const index = AUnit.unit_ids.findIndex((id) => id === unit_id)
    if (index !== -1) {
      AUnit.unit_ids.splice(index, -1)
    }
  }

  public static async getByIds(
    unit_ids: number[],
    params: IListUnitParams = {}
  ) {
    // @ts-ignore
    const units = await AUnit.list({ unit_id: unit_ids, ...params })
    return units
  }

  public static async getById(unit_id: number, params: IListUnitParams = {}) {
    // @ts-ignore
    const unit = await AUnit.list({ unit_id: unit_id, ...params })
    if (unit.length) {
      return unit[0]
    } else {
      return null
    }
  }

  public static getChangeEventNameUnit(Id: string | number) {
    return `ACTION_CHANGE_UNIT_${Id}`
  }

  // ========================================================================================

  constructor(config: ActionConstructor) {
    super(config)
    MaponSDK.event.addListener(this.CHANGE_EVENT, (data: Unit) => {
      const updated = this.setData({ data })
      if (updated) {
        MaponSDK.event.emit(`${this.unit_id}_MESSAGE_CHANGED`, data)
      }
    })
  }

  public isUpdated(unit: Unit): boolean {
    return this.last_update !== unit.last_update
  }

  public getIdStr() {
    return `UNIT_${this.unit_id}`
  }

  public get getId() {
    return this.unit_id
  }

  public getName() {
    return this.number
  }

  public static async list(params: IListUnitParams = {}, { raw } = { raw: false }): Promise<AUnit[]> {
    const res = await super.AList<{ units: Unit[] }>(params, { action: 'unit' })
    if (!raw) {
      const aUnits = res.data.units.map((unit) => new AUnit({ data: unit }))
      return aUnits
    } else {
      // @ts-ignore
      return res.data.units
    }
  }

  public static async toogleDriver({ driver_id, unit_id }: { driver_id: number | string; unit_id: number | string }): Promise<boolean> {
    const res = await super.AEdit<{ status: 'ok' }>({ driver_id, unit_id }, { action: 'unit' })
    if (res.data.status === 'ok') {
      return true
    }
    return false
  }

  public addEventListener(type: 'MESSAGE_CHANGED' | 'POSITION_CHANGED' | 'DRIVERS_CHANGED', callback: (data: Unit) => void) {
    this.subscribedEventsCount++
    AUnit.susbscribeUnitToData(this.unit_id)
    switch (type) {
      case 'MESSAGE_CHANGED':
        MaponSDK.event.addListener(`${this.unit_id}_MESSAGE_CHANGED`, callback)
        break
      case 'POSITION_CHANGED':
        MaponSDK.event.addListener(`${this.unit_id}_POSITION_CHANGED`, callback)
        break
      case 'DRIVERS_CHANGED':
        MaponSDK.event.addListener(`${this.unit_id}_DRIVERS_CHANGED`, callback)
        break
    }
  }

  public removeEventListener(type: 'MESSAGE_CHANGED' | 'POSITION_CHANGED' | 'DRIVERS_CHANGED', callback: (data: Unit) => void) {
    this.subscribedEventsCount--
    if (this.subscribedEventsCount <= 0) {
      AUnit.unsusbscribeUnitToData(this.unit_id)
    }
    switch (type) {
      case 'MESSAGE_CHANGED':
        MaponSDK.event.removeListener(`${this.unit_id}_MESSAGE_CHANGED`, callback)
        break
      case 'POSITION_CHANGED':
        MaponSDK.event.removeListener(`${this.unit_id}_POSITION_CHANGED`, callback)
        break
      case 'DRIVERS_CHANGED':
        MaponSDK.event.removeListener(`${this.unit_id}_DRIVERS_CHANGED`, callback)
        break
    }
  }

  public get googleLink() {
    return `http://maps.google.com/?q=${this.lat},${this.lng}`
  }

  public get getLastMessage(): Unit | null {
    return this.lastData
  }

  public getIcon() {
    return this.lastData?.icon
  }

  public get getCoordinates() {
    return `${this.lastData?.lat},${this.lastData?.lng}`
  }

  public get getCoordinatesRaw() {
    return { x: this.lastData?.lat, y: this.lastData?.lng }
  }

  public get getDriversList() {
    return this.lastData?.drivers ? Object.values(this.lastData?.drivers) : []
  }

  protected onDataUpdated(prevData: Unit | null, lastData: Unit): void {
    if (prevData) {
      if (prevData?.lat !== lastData.lat || prevData?.lng !== lastData.lng) {
        MaponSDK.event.emit(`${this.unit_id}_POSITION_CHANGED`, lastData)
      }
      if (!isEqual(prevData?.drivers, lastData.drivers)) {
        MaponSDK.event.emit(`${this.unit_id}_DRIVERS_CHANGED`, lastData)
      }
    }
  }

  public getGPSBateryPercentage() {
    if (!this.lastData?.battery_level_percentage?.value) return null
    return this.lastData?.battery_level_percentage?.value
  }

  public getGPSBatery() {
    if (!this.lastData?.battery_voltage?.value) return null
    return this.lastData?.battery_voltage?.value
  }

  public getUnitBatery() {
    if (!this.lastData?.supply_voltage?.value) return null
    return this.lastData?.supply_voltage?.value
  }

  public getDrivers(): Driver[] {
    const drivers = Object.values(this.lastData?.drivers || {})
    return drivers
  }

  public static async getRouteList(params: IRouteListParameters): Promise<Route> {
    // @ts-ignore
    const { data } = await MaponSDK.config.axios?.get<any>(`${MaponSDK.config.baseUrl}/route/list.json?key=${MaponSDK.config.token}`, serialize(params), {
      headers: {
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        accept: '*/*'
      }
    })
    return data
  }

  public static async getDistancesByDates({ from, till, unit_id }: { from: string, till: string; unit_id: string | string[] }) {
    const data = await this.getRouteList({ from, till, unit_id })
    const sum_distances = data.data.units.reduce((prev, curr) => {
      if (!prev[curr.unit_id]) prev[curr.unit_id] = 0
      // @ts-ignore
      prev[curr.unit_id] = curr.routes.reduce((prevVal, currVal) => {
        if (!currVal.distance) return
        prevVal += currVal.distance
      }, 0 as number)

      return prev
    }, {} as { [key: string]: number })

    return sum_distances
  }

}
