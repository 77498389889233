import Driver from '@/store/interfaces/Driver'
import { ReportItem } from '@/store/interfaces/ReportModules'
import ReportItemData from './ReportItemData'
import getSecondsDiffFromNow from '@/utils/getSecondsDiffFromNow'
import { REPORT_ITEM_IGNITION_STATE } from '@/store/catalogs/REPORT_ITEM_IGNITION_STATE';

export default class RICommon extends ReportItemData<any, any>
  implements ReportItem {
  getCurrPlataformGeofencesIds (message: { y: any; x: any }): Promise<number[]> {
    throw new Error('Method not implemented.')
  }

  unsusbscribeToUnitMessages (): void {
    // ---
  }

  disabled = true

  getUnitName (): string {
    return this.ri_unit
  }

  susbscribeToUnitMessages (): void {
    // --
  }

  bateryFlag = ''
  batteryGPSLevel (): number {
    return -1
  }

  batteryUnitLevel (): number {
    return -1
  }

  getLastMessageTimeInMillisecons (): number {
    return -1
  }

  bateryPercentage (): number {
    return 0
  }

  async getUbication (): Promise<string> {
    // ---
    return ''
  }

  stopUnitMessageObserver (): void {
    // ---
  }

  stoppedTime (): string {
    return ''
  }

  async isOnZone (message: { y: any; x: any }): Promise<boolean> {
    return true
  }

  initLocationObserver (): void {
    // ---
  }

  stopLocationObserver (): void {
    // ---
  }

  getIcon (): string {
    return ''
  }

  async getIgnitionState (): Promise<REPORT_ITEM_IGNITION_STATE> {
    return 3
  }

  getCoordinates (): string {
    return ''
  }

  async getLinkCamera () {
    return ''
  }

  async getHistoryVideo () {
    return ''
  }

  speed (): number {
    return 0
  }

  getGoogleLink (): string {
    return ''
  }

  getCurrentCoordinatesRaw (): { x: any; y: any } {
    return { x: null, y: null }
  }

  getResDrivers (): Driver[] {
    return []
  }

  async getSensors (): Promise<{ name: string; result: string; id: number }[]> {
    return []
  }

  getCommandList (): any[] {
      return []
  }

  async executeCommand (payload: any) {
    // --
  }

  async automaticReport () {
    // --
  }

  initAutomaticReportObserver (): void {
    // --
  }

  stopAutomaticReportObserver (): void {
    // --
  }
}
