import { GeofencesState } from './state';
import { Geofence, ResourceGeofences, RouteDestination } from '@/store/interfaces/Geofences'

export default {
  SET_MODAL_STATE (state: GeofencesState, payload: {
    modal: boolean
    id_group: number
  }): void {
    state.geofencesCrud = payload
  },
  UPDATE_CONSOLE_GEOFENCE (state: GeofencesState, { geofence, geofence_index, index }: { geofence: Geofence; index: number; geofence_index: number }): void {
    Object.assign(state.geofences[index].geofences[geofence_index], geofence)
    Object.assign(state.geofences[index].auxGeofences[geofence_index], geofence)
  },
  SET_GEOFENCES_BY_INDEX (state: GeofencesState, { rGeofence, index }: { rGeofence: ResourceGeofences; index: number }): void {
    state.geofences[index].geofences = rGeofence.geofences
    state.geofences[index].auxGeofences = rGeofence.auxGeofences
  },
  UPDATE_GEOFENCES (state: GeofencesState, { index, geofences }: { index: number; geofences: ResourceGeofences }): void {
    state.geofences[index] = geofences
  },
  SET_GEOFENCES (state: GeofencesState, geofences: Array<ResourceGeofences>): void {
    state.geofences = geofences
  },
  PUSH_GEOFENCES (state: GeofencesState, geofences: ResourceGeofences) {
    const index = state.geofences.findIndex(obj => obj.id === geofences.id && obj.pType === geofences.pType)
    if (index === -1) {
      state.geofences.push(geofences)
    } else {
      Object.assign(state.geofences[index], geofences)
    }
  },
  SET_AUX_GEOFENCES (state: GeofencesState, geoPayload: { resource_index: number, geofences?: Array<Geofence> }): void {
    const resource = state.geofences[geoPayload.resource_index]
    if (geoPayload.geofences) {
      resource.auxGeofences = geoPayload.geofences
    } else {
      resource.auxGeofences = resource.geofences
    }
  },
  SET_INPUT_VALUE (state: GeofencesState, val: { resource_index: number, value: string }): void {
    state.geofences[val.resource_index].inputSearch = val.value
  },
  SET_ROUTES_DESTINATIONS (state: GeofencesState, routeDestinations: Array<{ id_group: number, id_plataform: number; route_destinations: Array<RouteDestination> }>): void {
    state.routesDestinationsGeofences = routeDestinations
  },
  ADD_ROUTE_DESTINATION (state: GeofencesState, rdPayload: { route_destination_index: number, route_destination: RouteDestination }): void {
    state.routesDestinationsGeofences[rdPayload.route_destination_index].route_destinations.push(rdPayload.route_destination)
  },
  UPDATE_ROUTE_DESTINATION (state: GeofencesState, rdPayload: { route_destinations_index: number, group_index: number, route_destination: RouteDestination }): void {
    const obj1 = state.routesDestinationsGeofences[rdPayload.group_index].route_destinations[rdPayload.route_destinations_index]
    const obj2 = rdPayload.route_destination
    Object.assign(obj1, obj2)
  },
  REMOVE_ROUTE_DESTINATION (state: GeofencesState, rdPayload: { group_index: number, route_destinations_index: number }): void {
    state.routesDestinationsGeofences[rdPayload.group_index].route_destinations.splice(rdPayload.route_destinations_index, 1)
  }
}
