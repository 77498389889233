export const WEEKDAY = {
  SUNDAY: 1,
  MONDAY: 2,
  TUESDAY: 3,
  WEDNESDAY: 4,
  THURSDAY: 5,
  FRIDAY: 6,
  SATURDAY: 7
}

export const WEEKDAY_NAME = {
  [WEEKDAY.MONDAY]: 'Lunes',
  [WEEKDAY.TUESDAY]: 'Martes',
  [WEEKDAY.WEDNESDAY]: 'Miercoles',
  [WEEKDAY.THURSDAY]: 'Jueves',
  [WEEKDAY.FRIDAY]: 'Viernes',
  [WEEKDAY.SATURDAY]: 'Sabado',
  [WEEKDAY.SUNDAY]: 'Domingo'
}

export const WEEKDAY_ARR = [
  {
    id: WEEKDAY.MONDAY,
    name: WEEKDAY_NAME[WEEKDAY.MONDAY]
  },
  {
    id: WEEKDAY.TUESDAY,
    name: WEEKDAY_NAME[WEEKDAY.TUESDAY]
  },
  {
    id: WEEKDAY.WEDNESDAY,
    name: WEEKDAY_NAME[WEEKDAY.WEDNESDAY]
  },
  {
    id: WEEKDAY.THURSDAY,
    name: WEEKDAY_NAME[WEEKDAY.THURSDAY]
  },
  {
    id: WEEKDAY.FRIDAY,
    name: WEEKDAY_NAME[WEEKDAY.FRIDAY]
  },
  {
    id: WEEKDAY.SATURDAY,
    name: WEEKDAY_NAME[WEEKDAY.SATURDAY]
  },
  {
    id: WEEKDAY.SUNDAY,
    name: WEEKDAY_NAME[WEEKDAY.SUNDAY]
  }
]
