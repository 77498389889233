/* eslint-disable no-use-before-define */
export interface IPlataformPlaces<IPlaceType = any> {
  [key: number | string]: IPlataformPlace<IPlaceType>
}

export interface IPlataformPlace<IPlaceType = any> {
  results: IPlaceType[]
  center: [number, number]
}

export interface ICommonPlace {
  counts: number
  latitude: number
  longitude: number
  rows: IPlaceRow[]
}

export interface IUnknownPlace {
  counts: number
  latitude: number
  longitude: number
  rows: IPlaceRow[]
}

export interface IPlaceRow {
  s: number
  t: number
  x: number
  y: number
}

export enum PlataformPlacesType {
  COMMON_PLACES = 1,
  UNKNOWN_PLACES = 2
}

export const plataformPlaceTypeStrings = {
  [PlataformPlacesType.COMMON_PLACES]: 'Coordenadas usuales',
  [PlataformPlacesType.UNKNOWN_PLACES]: 'Coordenadas inusuales'
}

export type IPlaceTypes = ICommonPlace | IUnknownPlace
