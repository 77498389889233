import { Geofence, RouteDestination } from '@/store/interfaces/Geofences'
import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE'
import { GeofencesState } from './state'

export default {
  geofencesItems (state: GeofencesState) {
    // @ts-ignore
    return (pType: PLATAFORM_TYPE, params: { id_wialon?: string, id_group?: number }): Geofence[] => {
      const console_geofences = state.geofences.find(rGeofence => (rGeofence.id === params.id_group && rGeofence.pType === PLATAFORM_TYPE.CONSOLA))?.auxGeofences || []
      switch (pType) {
        case PLATAFORM_TYPE.CONSOLA: {
          // @ts-ignore
          const rGeofence = state.geofences.find(rGeofence => (rGeofence.id === params.id_group && rGeofence.pType === PLATAFORM_TYPE.CONSOLA))
          return [...(rGeofence?.auxGeofences || [])]
        }
        case PLATAFORM_TYPE.WIALON: {
          // @ts-ignore
          const rGeofence = state.geofences.find(rGeofence => (rGeofence.id === parseInt(params.id_wialon) && rGeofence.pType === PLATAFORM_TYPE.WIALON))
          return [...(rGeofence?.auxGeofences || []), ...console_geofences]
        }
        case PLATAFORM_TYPE.MAPON: {
          const rGeofence = state.geofences.find(rGeofence => rGeofence.pType === PLATAFORM_TYPE.MAPON)
          return [...(rGeofence?.auxGeofences || []), ...console_geofences]
        }
        case PLATAFORM_TYPE.TRACKSOLID: {
          // @ts-ignore
          const rGeofence = state.geofences.find(rGeofence => (rGeofence.id === params.id_group && rGeofence.pType === PLATAFORM_TYPE.CONSOLA))
          return [...(rGeofence?.auxGeofences || []), ...console_geofences]
        }
        default: {
          return []
        }
      }
    }
  },
  routesItems (state: GeofencesState) {
    return (pType: PLATAFORM_TYPE, params: { id_group: number }): RouteDestination[] => {
      if (pType === PLATAFORM_TYPE.WIALON) {
        // @ts-ignore
        const routesItems = state?.routesDestinationsGeofences?.find(rDGeofences => rDGeofences.id_group === params.id_group)
        if (routesItems) {
          return routesItems.route_destinations
        }
      } else if (pType === PLATAFORM_TYPE.MAPON) {
        const routeGroupItems = state.routesDestinationsGeofences.filter(rdg => rdg.id_plataform === PLATAFORM_TYPE.MAPON)
        const route_destinations = routeGroupItems.flatMap(rdg => rdg.route_destinations)
        if (route_destinations.length) {
          return route_destinations
        }
      } else if (pType === PLATAFORM_TYPE.TRACKSOLID) {
        const routeGroupItems = state.routesDestinationsGeofences.filter(rdg => rdg.id_plataform === PLATAFORM_TYPE.TRACKSOLID && rdg.id_group === params.id_group)
        const route_destinations = routeGroupItems.flatMap(rdg => rdg.route_destinations)
        if (route_destinations.length) {
          return route_destinations
        }
      }

      return []
    }
  },
  inputResource (state: GeofencesState) {
    return (pType: PLATAFORM_TYPE, params: { id_wialon?: string }): string => {
      if (pType === PLATAFORM_TYPE.WIALON) {
        // @ts-ignore
        const rGeofence = state.geofences.find(rGeofence => (rGeofence.id === parseInt(params.id_wialon) && rGeofence.pType === PLATAFORM_TYPE.WIALON))
        if (rGeofence) {
          return rGeofence.inputSearch
        }
      } else if (pType === PLATAFORM_TYPE.MAPON) {
        const rGeofence = state.geofences.find(rGeofence => rGeofence.pType === PLATAFORM_TYPE.MAPON)
        if (rGeofence) {
          return rGeofence.inputSearch
        }
      }

      return ''
    }
  },
  allAvaliableGeofences (state: GeofencesState): Geofence[] {
    const geofences: Geofence[] = []
    for (const rgeofences of state.geofences) {
      geofences.push(...rgeofences.geofences)
    }
    return geofences
  }
}
