import { Geofence } from '@/store/interfaces/Geofences'
import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE'
import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export interface RouteDestination {
  id_route_destinations: number
  rd_geofences: { id: number, n: string; pType?: PLATAFORM_TYPE }[]
  rd_name: string
  geofences?: Geofence[]
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
