import { ActionContext } from 'vuex'
import { State } from '../state'
import { IPlataformPlace, IPlataformPlaces, PlataformPlacesType } from '@/store/interfaces/Plataform'
import ReportItemData from '@/store/packages/ReportItem/ReportItemData';
import { IAddUnitAnaliticsItem } from '../mutations';

export default {
  async loadAnaliticsUnitSelectedPlaces ({ dispatch }: ActionContext<State, any>, payload: { params: any; pptype: PlataformPlacesType }) {
    switch (payload.pptype) {
      case PlataformPlacesType.COMMON_PLACES:
        return await dispatch('loadAnaliticsUnitPlaces', {
          url: 'plataform/analitics/units_commom_places',
          params: payload.params
        })
      case PlataformPlacesType.UNKNOWN_PLACES:
        return await dispatch('loadAnaliticsUnitPlaces', {
          url: 'plataform/analitics/units_unknown_places',
          params: payload.params
        })
    }
  },
  async loadAnaliticsUnitPlaces (
    { dispatch, commit }: ActionContext<State, any>,
    payload: {
      url: string,
      params: {
        rest: any,
        units: {
          pptype: PlataformPlacesType,
          id_plataform: number,
          resource_ids: string[],
          unit_id: string
          report_item: ReportItemData<unknown, unknown>
        }[]
      }
    }
  ) {
    commit('SET_STATE', { loadingUnitsAnalytics: true })
    try {
      const params = payload.params.units.map(({ id_plataform, unit_id, resource_ids }) => ({ id_plataform, unit_id, resource_ids }))
      const { data }: { data: IPlataformPlaces<any> } = await dispatch(
        'sys/axios',
        {
          url: payload.url,
          method: 'POST',
          data: {
            ...payload.params.rest,
            units: params
          }
        },
        {
          root: true
        }
      )

      payload.params.units.forEach(item => {
        const results = data[item.unit_id] as IPlataformPlace<any>
        if (!results) return

        commit('ADD_UNIT_ANALITICS_ITEM', {
          key: `${item.report_item.id_report_item}${item.pptype}`,
          data: {
            pptype: item.pptype,
            currMarkerGeofences: [],
            places: results.results,
            reportItem: item.report_item
          }
        } as IAddUnitAnaliticsItem)
      })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'LOAD_ANALITICS_UNIT_PLACES', color: 'error', message: '', error }, { root: true })
      throw error
    } finally {
      commit('SET_STATE', { loadingUnitsAnalytics: false })
    }
  }
}
