import { Plan, Sim as ISim, Company, Technician as ITechnician, Equipment as IEquipment, Brand, Model, TechnicianEquipment, TechnicianSims, Column, Action, IInstalation, IDefaultColumns, IEquipmentClass } from '@/store/interfaces/Binnacle';
import trimAll from '@/utils/trimAll';
import { serializeError } from 'serialize-error';
import { Socket } from 'socket.io-client';
import { ActionContext } from 'vuex';
import { State } from './state';
import Technician from '@/store/packages/Binnacle/Technician';
import Equipment from '@/store/packages/Binnacle/Equipment';
import Sim from '@/store/packages/Binnacle/Sim';
import bit_inventory_item from './actions/bit_inventory_item';
import technician from './actions/technician';
import Installation from '@/store/packages/Binnacle/Installation';

export default {
  async init (context: ActionContext<State, string>): Promise<void> {
    // @ts-ignore
    const socket: Socket = context.rootState.sys.socket

    // SIM
    socket.on('bit_sim_created', data => context.dispatch('socket_simCreated', data))
    socket.on('bit_sim_deleted', data => context.dispatch('socket_simDeleted', data))
    socket.on('bit_sim_updated', data => context.dispatch('socket_simUpdated', data))
    socket.on('bit_sim_technician_binded', data => context.dispatch('socket_simTechnicalBinded', data))
    socket.on('bit_sim_technician_unbinded', data => context.dispatch('socket_simTechnicalUnBinded', data))
    socket.on('bit_sim_status_active', data => context.dispatch('socket_simStatus', data))

    // PLAN
    socket.on('bit_plan_created', data => context.dispatch('socket_planCreated', data))
    socket.on('bit_plan_deleted', data => context.dispatch('socket_planDeleted', data))
    socket.on('bit_plan_updated', data => context.dispatch('socket_planUpdated', data))

    // Action
    socket.on('bit_action_created', data => context.dispatch('socket_actionCreated', data))
    socket.on('bit_action_deleted', data => context.dispatch('socket_actionDeleted', data))
    socket.on('bit_action_updated', data => context.dispatch('socket_actionUpdated', data))

    // Company
    socket.on('bit_company_created', data => context.dispatch('socket_companyCreated', data))
    socket.on('bit_company_deleted', data => context.dispatch('socket_companyDeleted', data))
    socket.on('bit_company_updated', data => context.dispatch('socket_companyUpdated', data))

    // Technical
    socket.on('bit_technical_created', data => context.dispatch('socket_technicalCreated', data))
    socket.on('bit_technical_deleted', data => context.dispatch('socket_technicalDeleted', data))
    socket.on('bit_technical_updated', data => context.dispatch('socket_technicalUpdated', data))
    socket.on('technician_item_properties_changed', data => context.dispatch('socket_technicianItemPropertiesChanged', data))
    socket.on('technician_inventory_add', data => context.dispatch('socket_technicianInventoryAdd', data))
    socket.on('technician_inventory_remove', data => context.dispatch('socket_technicianInventoryRemove', data))

    // Equipement
    socket.on('bit_equipment_created', data => context.dispatch('socket_equipmentCreated', data))
    socket.on('bit_equipment_deleted', data => context.dispatch('socket_equipmentDeleted', data))
    socket.on('bit_equipment_updated', data => context.dispatch('socket_equipmentUpdated', data))
    socket.on('bit_equipment_technician_binded', data => context.dispatch('socket_equipmentTechnicalBinded', data))
    socket.on('bit_equipment_technician_unbinded', data => context.dispatch('socket_equipmentTechnicalUnBinded', data))
    socket.on('bit_equipment_sim_binded', data => context.dispatch('socket_equipmentSimBinded', data))
    socket.on('bit_equipment_sim_unbinded', data => context.dispatch('socket_equipmentSimUnBinded', data))
    socket.on('bit_equipment_status_active', data => context.dispatch('socket_equipmentStatus', data))

    // Brand
    socket.on('bit_brand_created', data => context.dispatch('socket_brandCreated', data))
    socket.on('bit_brand_deleted', data => context.dispatch('socket_brandDeleted', data))
    socket.on('bit_brand_updated', data => context.dispatch('socket_brandUpdated', data))

    // Model
    socket.on('bit_model_created', data => context.dispatch('socket_modelCreated', data))
    socket.on('bit_model_deleted', data => context.dispatch('socket_modelDeleted', data))
    socket.on('bit_model_updated', data => context.dispatch('socket_modelUpdated', data))

    // Columns
    socket.on('bit_column_created', data => context.dispatch('socket_columnCreated', data))
    socket.on('bit_column_deleted', data => context.dispatch('socket_columnDeleted', data))
    socket.on('bit_column_updated', data => context.dispatch('socket_columnUpdated', data))
    socket.on('bit_columns_order_updated', data => context.dispatch('socket_columnOrderUpdated', data))

    // Insrallations
    socket.on('bit_installation_created', data => context.dispatch('socket_installationCreated', data))
    socket.on('bit_installation_deleted', data => context.dispatch('socket_installationDeleted', data))
    socket.on('bit_installation_updated', data => context.dispatch('socket_installationUpdated', data))

    // Default columns
    socket.on('bit_default_column_change', data => context.dispatch('socket_DefaultColumnChange', data))

    // Equipment class
    socket.on('bit_equipment_class_created', data => context.dispatch('socket_EquipmentClassCreated', data))
    socket.on('bit_equipment_class_updated', data => context.dispatch('socket_EquipmentClassUpdated', data))
    socket.on('bit_equipment_class_deleted', data => context.dispatch('socket_EquipmentClassDeleted', data))

    // Inventory items
    socket.on('bit_inventory_item_created', data => context.dispatch('socket_InventoryItemCreated', data))
    socket.on('bit_inventory_item_deleted', data => context.dispatch('socket_InventoryItemDeleted', data))
    socket.on('bit_inventory_item_updated', data => context.dispatch('socket_InventoryItemUpdated', data))
  },
  socket_EquipmentClassCreated ({ state }: ActionContext<State, string>, payload: IEquipmentClass) {
    state.data.equipments_class.push(payload)
  },
  socket_EquipmentClassUpdated ({ state, commit }: ActionContext<State, string>, payload: IEquipmentClass) {
    const index = state.data.equipments_class.findIndex(obj => obj.id_equipment_class === payload.id_equipment_class)
    if (index === -1) return;
    commit('UPDATE_EQUIPMENT_CLASS', { index, data: payload })
  },
  socket_EquipmentClassDeleted ({ state, commit }: ActionContext<State, string>, payload: IEquipmentClass) {
    const index = state.data.equipments_class.findIndex(obj => obj.id_equipment_class === payload.id_equipment_class)
    if (index === -1) return;
    commit('DELETE_EQUIPMENT_CLASS', index)
  },
  async copySim ({ dispatch, commit }: ActionContext<State, string>, sim: ISim) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `sim/copy/${sim.id_sim}`,
          method: 'POST',
          data: {
            sim_line: sim.sim_line
          }
        },
        { root: true }
      );

      return data;
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_COPY_SIM',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async copyEquipment ({ dispatch, commit }: ActionContext<State, string>, equipment: IEquipment) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `equipment/copy/${equipment.id_equipment}`,
          method: 'POST',
          data: {
            eq_imei: equipment.eq_imei
          }
        },
        { root: true }
      );

      return data;
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_COPY_EQUIPMENT',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async socket_simStatus ({ state }: ActionContext<State, string>, payload: { id_sim: string; sim_status_active: boolean }) {
    const sim = state.data.sims.find(obj => obj.id_sim === parseInt(payload.id_sim))
    if (sim) {
      sim.sim_status_active = payload.sim_status_active
    }
  },
  socket_DefaultColumnChange ({ state }: ActionContext<State, string>, payload: IDefaultColumns) {
    const index = state.data.default_columns.findIndex(obj => obj.id_default_column === payload.id_default_column)
    if (index !== -1) {
      state.data.default_columns[index].dfc_enabled = payload.dfc_enabled
    }
  },
  async simInactivation ({ dispatch, commit }: ActionContext<State, string>, id_sim: number) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `sim/status/inactive/${id_sim}`,
          method: 'POST'
        },
        { root: true }
      );

      return data;
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_INACTIVE_SIM',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async simActivation ({ dispatch, commit }: ActionContext<State, string>, id_sim: number) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `sim/status/active/${id_sim}`,
          method: 'POST'
        },
        { root: true }
      );

      return data;
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_ACTIVE_SIM',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async equipmentInactivation ({ dispatch, commit }: ActionContext<State, string>, id_equipment: number) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `equipment/status/inactive/${id_equipment}`,
          method: 'POST'
        },
        { root: true }
      );

      return data;
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_INACTIVE_EQUIPMENT',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async equipmentActivation ({ dispatch, commit }: ActionContext<State, string>, id_equipment: number) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `equipment/status/active/${id_equipment}`,
          method: 'POST'
        },
        { root: true }
      );

      return data;
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_ACTIVE_EQUIPMENT',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  socket_equipmentStatus ({ state }: ActionContext<State, string>, payload: { id_equipment: string; eq_status_active: boolean }) {
    const equipment = state.data.equipments.find(obj => obj.id_equipment === parseInt(payload.id_equipment))
    if (equipment) {
      equipment.eq_status_active = payload.eq_status_active
    }
  },
  changeBinnacleTab ({ dispatch, commit }: ActionContext<State, string>) {
    dispatch('sys/changeMonitTab', 'tab-Bitacora', { root: true });
    setTimeout(() => {
      commit('UPDATE_KEY')
    }, 100)
  },
  async socket_installationCreated ({ commit }: ActionContext<State, string>, payload: IInstalation) {
    console.log(payload)
    commit('ADD_INSTALLATION', payload)
  },
  async socket_installationUpdated ({ state, commit }: ActionContext<State, string>, payload: IInstalation) {
    const index = state.data.installations.findIndex(obj => obj.id_installation === payload.id_installation)
    if (index !== -1) {
      commit('UPDATE_INSTALLATION', { data: payload, index })
    }
  },
  async socket_installationDeleted ({ state, commit }: ActionContext<State, string>, payload: IInstalation) {
    const index = state.data.installations.findIndex(obj => obj.id_installation === payload.id_installation)
    if (index !== -1) {
      commit('DELETE_INSTALLATION', index)
    }
  },
  fakeGetAllInstallation ({ state, commit }: ActionContext<State, string>) {
    const installations = Object.assign([], state.data.installations)
    commit('SET_STATE_DATA', { installations });
  },
  async getRegisterInstallation ({ dispatch, commit }: ActionContext<State, string>, { id_installation, limit = 5 }: { id_installation: number; limit: number }) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `installations/register/${id_installation}/${limit}`,
          method: 'GET'
        },
        { root: true }
      );

      return data;
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_INSTALLATION_GET_ALL',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async sendCSVFile ({ commit, dispatch }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'installations/csv',
          method: 'POST',
          data: payload
        },
        { root: true }
      );

      return data
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_CSV_FILE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async getAllInstallation ({ dispatch, commit }: ActionContext<State, string>, payload?: IInstalation[]) {
    try {
      let installations: IInstalation[];
      if (!payload) {
        const { data } = await dispatch(
          'sys/axios',
          {
            url: 'installations/get/all',
            method: 'GET'
          },
          { root: true }
        );

        installations = data
      } else {
        installations = payload
      }

      commit('SET_STATE_DATA', { installations });

      return installations;
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_INSTALLATION_GET_ALL',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async createInstallation (
    { dispatch, commit }: ActionContext<State, string>,
    payload: any
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'installations/create',
          method: 'POST',
          data: payload,
          headers: {
            'Content-Type': 'multipart/form-data;'
          }
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_INSTALLATION_CREATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async updateInstallationProp (
    { dispatch, commit, state }: ActionContext<State, string>,
    payload: any
  ) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `installations/update/property/${payload.id_installation}`,
          method: 'PUT',
          data: payload.data
        },
        { root: true }
      );
      const index = state.data.installations.findIndex(obj => obj.id_installation === data.id_installation)
      if (index !== -1) {
        commit('UPDATE_INSTALLATION', { index, data })
      }
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_INSTALLATION_UPDATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async updateInstallation (
    { dispatch, commit }: ActionContext<State, string>,
    payload: any
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `installations/update/row/${payload.data_additional.id_installation}`,
          method: 'PUT',
          data: payload
        },
        { root: true }
      )
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_INSTALLATION_UPDATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async updateInstallationCustom (
    { dispatch, commit, state }: ActionContext<State, string>,
    payload: any
  ) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `installations/update/custom/${payload.id_installation}`,
          method: 'PUT',
          data: payload.data
        },
        { root: true }

      );
      const index = state.data.installations.findIndex(obj => obj.id_installation === data.id_installation)
      if (index !== -1) {
        commit('UPDATE_INSTALLATION', { index, data })
      }
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_INSTALLATION_UPDATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async deleteInstallation (
    { dispatch, commit }: ActionContext<State, string>,
    id: number
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `installations/delete/${id}`,
          method: 'DELETE'
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_INSTALLATION_DELETE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async getAllAction ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'action/get/all',
          method: 'GET'
        },
        { root: true }
      );

      commit('SET_STATE_DATA', { actions: data });

      return data;
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_ACTION_GET_ALL',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async createAction (
    { dispatch, commit }: ActionContext<State, string>,
    payload: Action
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'action/create',
          method: 'POST',
          data: trimAll(payload)
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_ACTION_CREATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async updateAction (
    { dispatch, commit }: ActionContext<State, string>,
    payload: Action
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `action/update/${payload.id_action}`,
          method: 'PUT',
          data: trimAll(payload)
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_ACTION_UPDATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async deleteAction (
    { dispatch, commit }: ActionContext<State, string>,
    id: number
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `action/delete/${id}`,
          method: 'DELETE'
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_ACTION_DELETE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async socket_actionCreated ({ state }: ActionContext<State, string>, payload: Action) {
    state.data.actions.push(payload)
  },
  async socket_actionUpdated ({ state }: ActionContext<State, string>, payload: Action) {
    const index = state.data.actions.findIndex(obj => obj.id_action === payload.id_action)
    if (index !== -1) {
      Object.assign(state.data.actions[index], payload)
    }
  },
  async socket_actionDeleted ({ state }: ActionContext<State, string>, payload: Action) {
    const index = state.data.actions.findIndex(obj => obj.id_action === payload.id_action)
    if (index !== -1) {
      state.data.actions.splice(index, 1)
    }
  },
  async getAllColumn ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'column/get/all',
          method: 'GET'
        },
        { root: true }
      );

      commit('SET_STATE_DATA', { columns: data });

      return data;
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_COLUMN_GET_ALL',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async createColumn (
    { dispatch, commit }: ActionContext<State, string>,
    payload: Column
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'column/create',
          method: 'POST',
          data: trimAll(payload)
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_COLUMN_CREATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async updateColumn (
    { dispatch, commit }: ActionContext<State, string>,
    payload: Column
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `column/update/${payload.id_column}`,
          method: 'PUT',
          data: trimAll(payload)
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_COLUMN_UPDATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async deleteColumn (
    { dispatch, commit }: ActionContext<State, string>,
    id: number
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `column/delete/${id}`,
          method: 'DELETE'
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_COLUMN_DELETE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async socket_columnCreated ({ state }: ActionContext<State, string>, payload: Column) {
    state.data.columns.unshift(payload)
  },
  async socket_columnUpdated ({ state }: ActionContext<State, string>, payload: Column) {
    const index = state.data.columns.findIndex(obj => obj.id_column === payload.id_column)
    if (index !== -1) {
      Object.assign(state.data.columns[index], payload)
    }
  },
  async socket_columnDeleted ({ state }: ActionContext<State, string>, payload: Column) {
    const index = state.data.columns.findIndex(obj => obj.id_column === payload.id_column)
    if (index !== -1) {
      state.data.columns.splice(index, 1)
    }
  },
  socket_equipmentSimUnBinded ({ state }: ActionContext<State, string>, payload: IEquipment) {
    const index = state.data.equipments.findIndex(eqp => eqp.id_equipment === payload.id_equipment)
    if (index !== -1) {
      const equipment = state.data.equipments[index]
      equipment.id_sim = null
    }
  },
  socket_equipmentSimBinded ({ state }: ActionContext<State, string>, payload: IEquipment) {
    const equipment = state.data.equipments.find(eqp => eqp.id_equipment === payload.id_equipment)
    if (equipment) {
      equipment.id_sim = payload.id_sim
    }
  },
  async bindEquipmentToSim ({ dispatch, commit }: ActionContext<State, string>, payload: { id_sim: number; id_equipment: number }) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `equipment/bind/sim/${payload.id_sim}/${payload.id_equipment}`,
          method: 'POST'
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_BIND_EQUIPMENT_SIM',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async unbindEquipmentToSim ({ dispatch, commit }: ActionContext<State, string>, payload: { id_sim: number; id_equipment: number }) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `equipment/unbind/sim/${payload.id_sim}/${payload.id_equipment}`,
          method: 'POST'
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_EQUIPMENT_UNBIND_SIM',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async bindEquipmentToTechnician ({ dispatch, commit }: ActionContext<State, string>, payload: { id_technician: number; id_equipment: number }) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `equipment/bind/technician/${payload.id_technician}/${payload.id_equipment}`,
          method: 'POST'
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_BIND_EQUIPMENT_TECHNICIAN',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async unbindEquipmentToTechnician ({ dispatch, commit }: ActionContext<State, string>, payload: { id_technician: number; id_equipment: number }) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `equipment/unbind/technician/${payload.id_technician}/${payload.id_equipment}`,
          method: 'POST'
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_EQUIPMENT_UNBIND_TECHNICIAN',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async bindSimToTechnician ({ dispatch, commit }: ActionContext<State, string>, payload: { id_technician: number; id_sim: number }) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `sim/bind/technician/${payload.id_technician}/${payload.id_sim}`,
          method: 'POST'
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_BIND_SIM_TECHNICIAN',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async unbindSimToTechnician ({ dispatch, commit }: ActionContext<State, string>, payload: { id_technician: number; id_sim: number }) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `sim/unbind/technician/${payload.id_technician}/${payload.id_sim}`,
          method: 'POST'
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_SIM_UNBIND',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  socket_equipmentTechnicalUnBinded ({ state }: ActionContext<State, string>, payload: TechnicianEquipment) {
    const technician = state.data.technicians.find(tech => tech.id_technician === payload.id_technician)
    if (technician) {
      const index = technician.equipments.findIndex(equip => equip.id_equipment === payload.id_equipment)
      if (index !== -1) {
        technician.equipments.splice(index, 1)
      }
    }
  },
  socket_equipmentTechnicalBinded ({ state }: ActionContext<State, string>, payload: TechnicianEquipment) {
    const technician = state.data.technicians.find(tech => tech.id_technician === payload.id_technician)
    if (technician) {
      technician.equipments.push(payload)
    }
  },
  socket_simTechnicalUnBinded ({ state }: ActionContext<State, string>, payload: TechnicianSims) {
    const technician = state.data.technicians.find(tech => tech.id_technician === payload.id_technician)
    if (technician) {
      const index = technician.sims.findIndex(sim => sim.id_sim === payload.id_sim)
      if (index !== -1) {
        technician.sims.splice(index, 1)
      }
    }
  },
  socket_simTechnicalBinded ({ state }: ActionContext<State, string>, payload: TechnicianSims) {
    const technician = state.data.technicians.find(tech => tech.id_technician === payload.id_technician)
    if (technician) {
      technician.sims.push(payload)
    }
  },
  async socket_modelCreated ({ state }: ActionContext<State, string>, payload: Model) {
    state.data.models.push(payload)
  },
  async socket_modelUpdated ({ state }: ActionContext<State, string>, payload: Model) {
    const index = state.data.models.findIndex(obj => obj.id_model === payload.id_model)
    if (index !== -1) {
      Object.assign(state.data.models[index], payload)
    }
  },
  async socket_modelDeleted ({ state }: ActionContext<State, string>, payload: Model) {
    const index = state.data.models.findIndex(obj => obj.id_model === payload.id_model)
    if (index !== -1) {
      state.data.models.splice(index, 1)
    }
  },
  async socket_brandCreated ({ state }: ActionContext<State, string>, payload: Brand) {
    state.data.brands.push(payload)
  },
  async socket_brandUpdated ({ state }: ActionContext<State, string>, payload: Brand) {
    const index = state.data.brands.findIndex(obj => obj.id_brand === payload.id_brand)
    if (index !== -1) {
      Object.assign(state.data.brands[index], payload)
    }
  },
  async socket_brandDeleted ({ state }: ActionContext<State, string>, payload: Brand) {
    const index = state.data.brands.findIndex(obj => obj.id_brand === payload.id_brand)
    if (index !== -1) {
      state.data.brands.splice(index, 1)
    }
  },
  async socket_simCreated ({ state }: ActionContext<State, string>, payload: ISim) {
    state.data.sims.unshift(new Sim({ data: payload }))
  },
  async socket_simUpdated ({ state }: ActionContext<State, string>, payload: ISim) {
    const index = state.data.sims.findIndex(obj => obj.id_sim === payload.id_sim)
    if (index !== -1) {
      Object.assign(state.data.sims[index], payload)
    }
  },
  async socket_simDeleted ({ state }: ActionContext<State, string>, payload: ISim) {
    const index = state.data.sims.findIndex(obj => obj.id_sim === payload.id_sim)
    if (index !== -1) {
      state.data.sims.splice(index, 1)
    }
  },
  async socket_equipmentCreated ({ state }: ActionContext<State, string>, payload: IEquipment) {
    state.data.equipments.unshift(new Equipment({ data: payload }))
  },
  async socket_equipmentUpdated ({ state }: ActionContext<State, string>, payload: IEquipment) {
    const index = state.data.equipments.findIndex(obj => obj.id_equipment === payload.id_equipment)
    if (index !== -1) {
      Object.assign(state.data.equipments[index], payload)
    }
  },
  async socket_equipmentDeleted ({ state }: ActionContext<State, string>, payload: IEquipment) {
    const index = state.data.equipments.findIndex(obj => obj.id_equipment === payload.id_equipment)
    if (index !== -1) {
      state.data.equipments.splice(index, 1)
    }
  },
  async socket_technicalCreated ({ state }: ActionContext<State, string>, payload: ITechnician) {
    state.data.technicians.push(new Technician({ data: payload }))
  },
  async socket_technicalUpdated ({ state }: ActionContext<State, string>, payload: ITechnician) {
    const index = state.data.technicians.findIndex(obj => obj.id_technician === payload.id_technician)
    if (index !== -1) {
      Object.assign(state.data.technicians[index], payload)
    }
  },
  async socket_technicalDeleted ({ state }: ActionContext<State, string>, payload: ITechnician) {
    const index = state.data.technicians.findIndex(obj => obj.id_technician === payload.id_technician)
    if (index !== -1) {
      state.data.technicians.splice(index, 1)
    }
  },
  async socket_planCreated ({ state }: ActionContext<State, string>, payload: Plan) {
    state.data.plans.push(payload)
  },
  async socket_planUpdated ({ state }: ActionContext<State, string>, payload: Plan) {
    const index = state.data.plans.findIndex(obj => obj.id_plan === payload.id_plan)
    if (index !== -1) {
      Object.assign(state.data.plans[index], payload)
    }
  },
  async socket_planDeleted ({ state }: ActionContext<State, string>, payload: Plan) {
    const index = state.data.plans.findIndex(obj => obj.id_plan === payload.id_plan)
    if (index !== -1) {
      state.data.plans.splice(index, 1)
    }
  },
  async socket_companyCreated ({ state }: ActionContext<State, string>, payload: Company) {
    state.data.companies.push(payload)
  },
  async socket_companyUpdated ({ state }: ActionContext<State, string>, payload: Company) {
    const index = state.data.companies.findIndex(obj => obj.id_company === payload.id_company)
    if (index !== -1) {
      Object.assign(state.data.companies[index], payload)
    }
  },
  async socket_companyDeleted ({ state }: ActionContext<State, string>, payload: Company) {
    const index = state.data.companies.findIndex(obj => obj.id_company === payload.id_company)
    if (index !== -1) {
      state.data.companies.splice(index, 1)
    }
  },
  async getAllEquipment ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'equipment/get/all',
          method: 'GET'
        },
        { root: true }
      );

      commit('SET_STATE_DATA', { equipments: data });

      return data;
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_EQUIPMENT_GET_ALL',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async createEquipment (
    { dispatch, commit }: ActionContext<State, string>,
    payload: IEquipment
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'equipment/create',
          method: 'POST',
          data: trimAll(payload)
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      // @ts-ignore
      if (error?.response?.status === 401) {
        await dispatch('sys/showNotificationMessage', {
          title: 'El IMEI ya se encuentra registrado',
          color: 'error',
          duration: -1
        }, { root: true });
      }
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_EQUIPMENT_CREATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async updateEquipment (
    { dispatch, commit }: ActionContext<State, string>,
    payload: IEquipment
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `equipment/update/${payload.id_equipment}`,
          method: 'PUT',
          data: trimAll(payload)
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_EQUIPMENT_UPDATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async deleteEquipment (
    { dispatch, commit }: ActionContext<State, string>,
    id: number
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `equipment/delete/${id}`,
          method: 'DELETE'
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_EQUIPMENT_DELETE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async getAllModel ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'model/get/all',
          method: 'GET'
        },
        { root: true }
      );

      commit('SET_STATE_DATA', { models: data });

      return data;
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_MODEL_GET_ALL',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async createModel (
    { dispatch, commit }: ActionContext<State, string>,
    payload: Model
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'model/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_MODEL_CREATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async updateModel (
    { dispatch, commit }: ActionContext<State, string>,
    payload: Model
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `model/update/${payload.id_model}`,
          method: 'PUT',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_MODEL_UPDATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async deleteModel (
    { dispatch, commit }: ActionContext<State, string>,
    id: number
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `model/delete/${id}`,
          method: 'DELETE'
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_MODEL_DELETE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async getAllBrand ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'brand/get/all',
          method: 'GET'
        },
        { root: true }
      );

      commit('SET_STATE_DATA', { brands: data });

      return data;
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_BRAND_GET_ALL',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async createBrand (
    { dispatch, commit }: ActionContext<State, string>,
    payload: Brand
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'brand/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_BRAND_CREATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async updateBrand (
    { dispatch, commit }: ActionContext<State, string>,
    payload: Brand
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `brand/update/${payload.id_brand}`,
          method: 'PUT',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_BRAND_UPDATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async deleteBrand (
    { dispatch, commit }: ActionContext<State, string>,
    id: number
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `brand/delete/${id}`,
          method: 'DELETE'
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_BRAND_DELETE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async getAllTechnician ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'technician/get/all',
          method: 'GET'
        },
        { root: true }
      );

      commit('SET_STATE_DATA', { technicians: data });

      return data;
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_TECHNICIAN_GET_ALL',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async createTechnician (
    { dispatch, commit }: ActionContext<State, string>,
    payload: ITechnician
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'technician/create',
          method: 'POST',
          data: trimAll(payload)
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_TECHNICIAN_CREATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async updateTechnician (
    { dispatch, commit }: ActionContext<State, string>,
    payload: any
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `technician/update/${payload.tech.id_technician}`,
          method: 'PUT',
          data: trimAll(payload)
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_TECHNICIAN_UPDATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async deleteTechnician (
    { dispatch, commit }: ActionContext<State, string>,
    id: number
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `technician/delete/${id}`,
          method: 'DELETE'
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_TECHNICIAN_DELETE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async getAllCompany ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'company/get/all',
          method: 'GET'
        },
        { root: true }
      );

      commit('SET_STATE_DATA', { companies: data });

      return data;
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_COMPANY_GET_ALL',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async createCompany (
    { dispatch, commit }: ActionContext<State, string>,
    payload: Company
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'company/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_COMPANY_CREATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async updateCompany (
    { dispatch, commit }: ActionContext<State, string>,
    payload: Company
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `company/update/${payload.id_company}`,
          method: 'PUT',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_COMPANY_UPDATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async deleteCompany (
    { dispatch, commit }: ActionContext<State, string>,
    id: number
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `company/delete/${id}`,
          method: 'DELETE'
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_COMPANY_DELETE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async getAllPlan ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'plan/get/all',
          method: 'GET'
        },
        { root: true }
      );

      commit('SET_STATE_DATA', { plans: data });

      return data;
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_PLAN_GET_ALL',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async createPlan (
    { dispatch, commit }: ActionContext<State, string>,
    payload: Plan
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'plan/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_PLAN_CREATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async updatePlan (
    { dispatch, commit }: ActionContext<State, string>,
    payload: Plan
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `plan/update/${payload.id_plan}`,
          method: 'PUT',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_PLAN_UPDATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async deletePlan (
    { dispatch, commit }: ActionContext<State, string>,
    id: number
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `plan/delete/${id}`,
          method: 'DELETE'
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_PLAN_DELETE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async getAllSim ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'sim/get/all',
          method: 'GET'
        },
        { root: true }
      );

      commit('SET_STATE_DATA', { sims: data });

      return data;
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_SIM_GET_ALL',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async createSim (
    { dispatch, commit }: ActionContext<State, string>,
    payload: ISim
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'sim/create',
          method: 'POST',
          data: trimAll(payload)
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      // @ts-ignore
      if (error?.response?.status === 401) {
        await dispatch('sys/showNotificationMessage', {
          title: 'La linea SIM ya se encuentra registrado',
          color: 'error',
          duration: -1
        }, { root: true });
      }
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_SIM_CREATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async updateSim (
    { dispatch, commit }: ActionContext<State, string>,
    payload: ISim
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `sim/update/${payload.id_sim}`,
          method: 'PUT',
          data: trimAll(payload)
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_SIM_UPDATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async deleteSim (
    { dispatch, commit }: ActionContext<State, string>,
    id: number
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `sim/delete/${id}`,
          method: 'DELETE'
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_SIM_DELETE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  getActionByActName ({ state }: ActionContext<State, string>, act_name: string) {
    if (!act_name) return null
    const data = state.data.actions.find(obj => (obj?.act_name || '').trim() === (act_name || '').trim())
    return data
  },
  getTechnicianByTecName ({ state }: ActionContext<State, string>, tec_name: string) {
    if (!tec_name) return null
    const data = state.data.technicians.find(obj => (obj?.tec_name || '').trim() === (tec_name || '').trim())
    return data
  },
  getEquipmentByEqImei ({ state }: ActionContext<State, string>, eq_imei: string) {
    if (!eq_imei) return null
    const data = state.data.equipments.find(obj => (obj?.eq_imei || '').trim() === (eq_imei || '').trim())
    return data
  },
  getSimBySimLine ({ state }: ActionContext<State, string>, sim_line: string) {
    if (!sim_line) return null
    const data = state.data.sims.find(obj => (obj?.sim_line || '').trim() === (sim_line || '').trim())
    return data
  },
  getActionById ({ state }: ActionContext<State, string>, id: number) {
    if (!id) return null
    const data = state.data.actions.find(obj => obj.id_action === id)
    return data
  },
  getTechnicianById ({ state }: ActionContext<State, string>, id: number) {
    if (!id) return null
    const data = state.data.technicians.find(obj => obj.id_technician === id)
    return data
  },
  getEquipmentById ({ state }: ActionContext<State, string>, id: number) {
    if (!id) return null
    const data = state.data.equipments.find(obj => obj.id_equipment === id)
    return data
  },
  getSimById ({ state }: ActionContext<State, string>, id: number) {
    if (!id) return null
    const data = state.data.sims.find(obj => obj.id_sim === id)
    return data
  },
  getTechnicianByEquipmentId ({ state }: ActionContext<State, string>, id: number) {
    if (!id) return null
    const data = state.data.technicians.find(tech => tech.equipments.some(te => te.id_equipment === id))
    return data
  },
  async socket_technicianItemPropertiesChanged ({ state }: ActionContext<State, string>, payload: ITechnician) {
    const tech = state.data.technicians.find(obj => obj.id_technician === payload.id_technician)
    if (tech) {
      tech.tec_properties = payload.tec_properties
    }
  },
  async technicianAdditionalPropertiesUpdate (context: ActionContext<State, string>, payload: any): Promise<void> {
    try {
      await context.dispatch(
        'sys/axios',
        {
          url: 'technician/additional_properties/update',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error)
      context.dispatch('sys/showNotificationMessage', {
        title: 'No se pudieron obtener los datos',
        type: 'error'
      }, {
        root: true
      })
      context.commit('sys/ADD_LOG', { title: 'TECHNICIAN_PROPERTIES_CHANGED', color: 'warning', message: '', payload: serializeError(error) }, { root: true })

      throw error
    }
  },
  async enableColumn (context: ActionContext<State, string>, payload: any): Promise<void> {
    try {
      await context.dispatch(
        'sys/axios',
        {
          url: 'column/enable',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error)
      context.dispatch('sys/showNotificationMessage', {
        title: 'No se pudo habilitar la columna',
        type: 'error'
      }, {
        root: true
      })
      context.commit('sys/ADD_LOG', { title: 'BIT_ENABLE_COLUMN', color: 'warning', message: '', payload: serializeError(error) }, { root: true })

      throw error
    }
  },
  async disableColumn (context: ActionContext<State, string>, payload: any): Promise<void> {
    try {
      await context.dispatch(
        'sys/axios',
        {
          url: 'column/disable',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error)
      context.dispatch('sys/showNotificationMessage', {
        title: 'No se pudo deshabilitar la columna',
        type: 'error'
      }, {
        root: true
      })
      context.commit('sys/ADD_LOG', { title: 'BIT_DISABLE_COLUMN', color: 'warning', message: '', payload: serializeError(error) }, { root: true })

      throw error
    }
  },
  async swapColumn (context: ActionContext<State, string>, payload: any): Promise<void> {
    try {
      await context.dispatch(
        'sys/axios',
        {
          url: 'column/order/swap',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error)
      context.dispatch('sys/showNotificationMessage', {
        title: 'No se pudo deshabilitar la columna',
        type: 'error'
      }, {
        root: true
      })
      context.commit('sys/ADD_LOG', { title: 'BIT_DISABLE_COLUMN', color: 'warning', message: '', payload: serializeError(error) }, { root: true })

      throw error
    }
  },
  socket_columnOrderUpdated ({ state }: ActionContext<State, string>, payload: any) {
    const index1: number = state.data.columns.findIndex(obj => obj.id_column === payload.column_1.id_column);
    const index2: number = state.data.columns.findIndex(obj => obj.id_column === payload.column_2.id_column);
    if (index1 === -1 || index2 === -1) return;
    state.data.columns[index1].col_order = payload.column_1.col_order
    state.data.columns[index2].col_order = payload.column_2.col_order
  },
  async equipmentClassAssing (
    { dispatch, commit }: ActionContext<State, string>,
    payload: { id_equipment_class: number; id_equipment: number; }
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `equipment/class/assing/${payload.id_equipment}/${payload.id_equipment_class}`,
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_EQUIPMENT_CLASS_CREATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async getAllClassEquipment ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'equipment/class/get/all',
          method: 'GET'
        },
        { root: true }
      );

      commit('SET_STATE_DATA', { equipments_class: data });

      return data;
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_EQUIPMENT_CLASS_GET_ALL',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async createEquipmentClass (
    { dispatch, commit }: ActionContext<State, string>,
    payload: Brand
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'equipment/class/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_EQUIPMENT_CLASS_CREATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async updateEquipmentClass (
    { dispatch, commit }: ActionContext<State, string>,
    payload: any
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'equipment/class/update',
          method: 'PUT',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_EQUIPMENT_CLAS_UPDATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async deleteEquipmentClass (
    { dispatch, commit }: ActionContext<State, string>,
    id: number
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `equipment/class/delete/${id}`,
          method: 'DELETE'
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_EQUIPMENT_CLASS_DELETE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async deleteTechnicianCost (
    { dispatch, commit }: ActionContext<State, string>,
    id_technician_cost: number
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `technician/costs/delete/${id_technician_cost}`,
          method: 'DELETE'
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'BIT_TECHNICIAN_COST_DELETE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async updateInstallationAdditionalData (
    { dispatch }: ActionContext<State, string>,
    payload: Installation
  ) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `installations/update/data_additional/${payload.id_installation}`,
          method: 'PUT',
          data: payload.ins_data_additional
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      dispatch('sys/addLogWithError', { title: 'BIT_UPDATE_DATA_ADDITIONAL', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  ...bit_inventory_item,
  ...technician
};
