import { IFile } from './Files';
import { UserSchemaSimple } from './User';
import { WRReport } from './wialon/Resource.interface';

/* eslint-disable no-use-before-define */

export interface IChatSchemaSimple {
  chat_thumbnail_file: IFile | null;
  cht_createdAt: string;
  cht_description: string;
  cht_name: string;
  cht_user_created_name?: string | null;
  id_chat: number;
  id_chat_type: number;
  user_created: string | null;
  chm_is_resend: boolean
}
export interface IChat {
  chat_messages: IChatMessage[];
  chat_thumbnail_file: IFile | null;
  chat_type: IChatType;
  chat_users: IChatUser[];
  chat_groups: IChatGroup[];
  chat_files_attached: IChatFileAttached[];
  cht_bot_context_message: string
  cht_createdAt: string;
  cht_description: string;
  cht_name: string;
  cht_bot_allowed: boolean
  cht_bot_active: boolean
  cht_user_created_name?: string | null;
  id_chat: number;
  id_chat_type: number;
  user_created: string | null;
  cht_additional_config: IChatAdditionalConfig
}

export interface IChatAdditionalConfig {
  [key: string]: any
  chatBotUnitTemplatesExamples?: {
    examples: {
      text_input: string
      input_options: string[],
      output: string
    }
    use_examples: boolean
  }
}

export interface IChatMessage {
  /** Fecha de creacion del mensaje */
  chm_createdAt: string;
  chm_message: string;
  chm_user_name: string;
  chm_user_name_deleted: string
  chm_is_edited: boolean
  id_chat_item: number;
  id_chat_message: number;
  id_user: number;
  chm_color: string
  id_chat_message_to_reply: number | null;
  chat_message_to_reply: IChatMessage | null
  chm_is_deleted: boolean
  id_chat_message_audio: number | null
  chat_message_audio: IFile | null
  chm_code_payload: { [key: string]: any }
  id_chat_message_type: CHAT_MESSAGE_TYPES
  id_chat_message_code: CHAT_MESSAGE_CODE
  message_files: IMessageFile[];
  chm_is_resend: boolean
  user?: {
    id_user: number;
    id_user_type: number;
    us_name: string;
    ut_name: string;
  }
}

export enum CHAT_MESSAGE_CODE {
  EMPTY = 0,
  USERS_REMOVED = 1,
  USERS_ADDED = 2,
  THUMBNAIL_CHANGED = 3
}

export enum CHAT_MESSAGE_TYPES {
  USER = 1,
  SYSTEM = 2
}

export default interface IChatMessageType {
  id_chat_message_type: number
  cmt_name: string
}

export interface IMessageFile {
  file: IFile;
  id_chat_message: number;
  id_chat_message_file: number;
  id_file: number;
}

export interface IChatType {
  ctp_name: string;
  id_chat_type: number;
}

export interface IChatUser {
  chu_createdAt: string;
  chu_silent_chat: boolean;
  chu_is_admin: boolean;
  id_chat_item: number;
  id_chat_user: number;
  id_user: number | null;
  chu_last_seen_message_id: number | null
  chu_is_hidden: boolean
  user: {
    id_user: number;
    id_user_type: number;
    last_time_conected?: string | null;
    thumbnail_file?: IFile
    us_name: string;
    ut_name: string;
  } | null
  chu_user_saved_data: UserSchemaSimple
}

export interface IChatGroup {
  id_chat_group_binded: number
  id_chat_item: number
  id_group: number
  group: {
    id_group: number
    gp_wialon_id: string
    gp_name: string
    gp_color: string
    id_plataform: number
    gp_status: boolean
    date_created: string
    date_delete: string
    id_user_delete: number
    id_clasificacion: number
    id_company: number
  }
}

export interface IChatFileAttached {
  id_chat_file_attached: number
  cfa_title: string
  cfa_description: string
  id_chat_file_type: number
  id_chat_item: number
  id_file: number
  file: IFile
}

export interface IChatPlataformNotification<cpn_payload = any> {
  id_chat_plataform_notification: number
  cpn_plataform_notification_id: number | string
  cpn_plataform_notification_name: string
  cpn_color: string
  cpn_payload: cpn_payload
  id_chat_item: number
  id_plataform: number
}

export interface ICPNWialonPayload {
  resource_id: string | number
  resource_name: string
  units_included: { id: number; nm: string }[]
  units_excluded: { id: number; nm: string }[]
}

export interface IChatPlataformReport<cpr_payload = any> {
  id_chat_plataform_report: number
  cpr_plataform_report_id: number | string
  cpr_plataform_report_name: string
  cpr_payload: cpr_payload
  cpr_default_message: string
  cpr_avaliable: boolean
  id_chat_item: number
  id_plataform: number
}

export interface ICPRWialonPayload {
  resource_id: number
  resource_name: string
  unit_target: number
  unit_name: string
  use_as_interval_current: boolean
  fire_date: { weekday: string | null; monthday: string | null; time: string }
  report: WRReport
  interval_count: number
  interval_type: number
  timeoffset: number
}

export interface IChatBotCase {
  id_chat_bot_cases: number
  cbc_case_name: string
  cbc_case_description: string
  cbc_llm_prompt: string
  cbc_default_answer: string
  cbc_alert: boolean
  cbc_alert_loud: boolean
  id_chat_item: number
  tools: IChatBotCaseToolBinded[]
}

export interface IChatBotCaseToolBinded {
  id_chat_bot_cases_tool_binded: number
  cbct_tool_type: number
  cbct_tool_properties: any
  id_chat_bot_cases: number
}

export interface IChatBotToolConfig {
  id_chat_bot_tool_config: number
  cbtc_tool_type: number
  cbtc_tool_properties: any
  id_chat_item: number
}

export interface IChatLogData {
  id_chat_log_data: number
  cld_type: number
  cld_data: any
  id_chat_item: number
  cld_createdAt: string
}
