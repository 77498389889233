import { UserConected } from '@/interfaces/Comunications.interface'
import ChatItem from '@/store/packages/Chat/ChatItem'
import { CombinedVueInstance } from 'vue/types/vue'

const state = {
  /** Abre o cierra el drawer de los chat en la consola */
  drawer: false,
  /** Chats listados en el drawer de la consola */
  chats: {} as {[key: number]: ChatItem},
  /** El chat que actuamente se renderiza */
  currChat: null as null | ChatItem,
  /** Indica si se esta mostrando la informacion del chat */
  showCurrChatInfo: false,
  /** Lista de usuarios conectados al socket del chat (Y que pertenecen a la misma compañia) */
  chatUsersConected: [] as UserConected[],
  /** Indica si el bot esta omitido para las respuesta del usuario actual */
  omitBot: true,
  /** Indica el id del mensaje que se tiene que enfocar */
  currMessageIdFocus: 0,
  /** El componente actualmente abierto */
  currChatComponent: null as CombinedVueInstance<any, any, any, any, any, any>,
  currPopupChatItem: {
    popup: false,
    chatItem: null as null | ChatItem
  }
}

export type State = typeof state

export default state
