/* eslint-disable @typescript-eslint/no-empty-function */
import datetimeToDate from '@/utils/datetimeToDate'

/* eslint-disable @typescript-eslint/no-unused-vars */
export enum EVENTS_TYPES {
  HIKVISION_DG_EVENTS = 1,
  HIKVISION_DG_SEARCH_DATA = 2
}

export const ET_HIKVISION = {
  GPSUpload: {
    type: 'GPSUpload',
    name: 'Evento del GPS',
    color: '#bfbfbf',
    getNotName (data: any) {
    },
    getNotMessage (data: any) {
    }
  },
  driverEyeClosed: {
    type: 'driverEyeClosed',
    name: 'Somnolencia',
    color: '#f83b8d',
    getNotName ({ event }: any) {
      return `Alerta de somnolencia en canal ${event.data?.EventNotificationAlert?.channelName}`
    },
    getNotMessage ({ event, report_item }: any) {
      return `Se ha detectado una notificacion Alerta de somnolencia (${event.data?.EventNotificationAlert?.eventDescription}) en canal ${event.data?.EventNotificationAlert?.channelName} en la unidad ${report_item.ri_unit} a las ${datetimeToDate(event.data?.EventNotificationAlert?.dateTime)}`
    }
  },
  driverInattention: {
    type: 'driverInattention',
    name: 'Conductor distraido',
    color: '#3b87f7',
    getNotName ({ event }: any) {
      return `Alerta de distraccion en canal ${event.data?.EventNotificationAlert?.channelName}`
    },
    getNotMessage ({ event, report_item }: any) {
      return `Se ha detectado una notificacion Alerta de distraccion (${event.data?.EventNotificationAlert?.eventDescription}) en canal ${event.data?.EventNotificationAlert?.channelName} en la unidad ${report_item.ri_unit} a las ${datetimeToDate(event.data?.EventNotificationAlert?.dateTime)}`
    }
  },
  driverSmoking: {
    type: 'driverSmoking',
    name: 'Conductor fumando',
    color: '#f7df3b',
    getNotName ({ event }: any) {
      return `Alerta de conductor fumando en canal ${event.data?.EventNotificationAlert?.channelName}`
    },
    getNotMessage ({ event, report_item }: any) {
      return `Se ha detectado una notificacion Alerta de conductor fumando (${event.data?.EventNotificationAlert?.eventDescription}) en canal ${event.data?.EventNotificationAlert?.channelName} en la unidad ${report_item.ri_unit} a las ${datetimeToDate(event.data?.EventNotificationAlert?.dateTime)}`
    }
  },
  driverCalling: {
    type: 'driverCalling',
    name: 'Conductor realizando llamada',
    color: '#f5942f',
    getNotName ({ event }: any) {
      return `Alerta de conductor usando el telefono en canal ${event.data?.EventNotificationAlert?.channelName}`
    },
    getNotMessage ({ event, report_item }: any) {
      return `Se ha detectado una notificacion Alerta de conductor usando el telefono (${event.data?.EventNotificationAlert?.eventDescription}) en canal ${event.data?.EventNotificationAlert?.channelName} en la unidad ${report_item.ri_unit} a las ${datetimeToDate(event.data?.EventNotificationAlert?.dateTime)}`
    }
  },
  mobilePeopleCounting: {
    type: 'mobilePeopleCounting',
    name: 'Movimiento en cabina',
    color: '#db4040',
    getNotName ({ event }: any) {
      return `Alerta de movimiento en cabina en canal ${event.data?.EventNotificationAlert?.channelName}`
    },
    getNotMessage ({ event, report_item }: any) {
      return `Se ha detectado una notificacion Alerta de movimiento en cabina (${event.data?.EventNotificationAlert?.eventDescription}) en canal ${event.data?.EventNotificationAlert?.channelName} en la unidad ${report_item.ri_unit} a las ${datetimeToDate(event.data?.EventNotificationAlert?.dateTime)}`
    }
  },
  FCW: {
    type: 'FCW',
    name: 'Colicion frontal',
    color: '#db4040',
    getNotName ({ event }: any) {
      return `Alerta de colision frontal canal ${event.data?.EventNotificationAlert?.channelName}`
    },
    getNotMessage ({ event, report_item }: any) {
      return `Se ha detectado una notificacion Alerta de colision frontal (${event.data?.EventNotificationAlert?.eventDescription}) en canal ${event.data?.EventNotificationAlert?.channelName} en la unidad ${report_item.ri_unit} a las ${datetimeToDate(event.data?.EventNotificationAlert?.dateTime)}`
    }
  },
  PCW: {
    type: 'PCW',
    name: 'Movimiento peatonal',
    color: '#4B77E7',
    getNotName ({ event }: any) {
    },
    getNotMessage ({ event, report_item }: any) {
    }
  },
  LDW: {
    type: 'LDW',
    name: 'Alerta de advertencia de cambio de carril',
    color: '#87bb10',
    getNotName ({ event }: any) {
      return `Alerta de advertencia de cambio de carril en canal ${event.data?.EventNotificationAlert?.channelName}`
    },
    getNotMessage ({ event, report_item }: any) {
      return `Se ha detectado una notificacion Alerta de advertencia de cambio de carril (${event.data?.EventNotificationAlert?.eventDescription}) en canal ${event.data?.EventNotificationAlert?.channelName} en la unidad ${report_item.ri_unit} a las ${datetimeToDate(event.data?.EventNotificationAlert?.dateTime)}`
    }
  },
  devStatusChanged: {
    type: 'devStatusChanged',
    name: 'Cambio de estado del dispositivo',
    color: '#07d9ad',
    getNotName ({ event }: any) {
      if (event.data?.EventNotificationAlert?.status === 'online') {
        return `Alerta de dispositivo ${event.data?.EventNotificationAlert?.deviceSerial} en linea `
      } else if (event.data?.EventNotificationAlert?.status === 'offline') {
        return `Alerta de dispositivo ${event.data?.EventNotificationAlert?.deviceSerial} fuera de linea `
      }
    },
    getNotMessage ({ event, report_item }: any) {
    }
  }
} as const
