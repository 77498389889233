/* eslint-disable no-new */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionContext } from 'vuex'
import isPromise from '@/utils/isPromise'
import isFunction from '@/utils/isFunction'
import axios, { AxiosRequestConfig } from 'axios'
import { Socket, io } from 'socket.io-client'
import { PLATAFORM_TYPE } from '../catalogs/PLATAFORM_TYPE'
import { serializeError } from 'serialize-error'
import { USER_TYPE } from '../catalogs/USER_TYPE'
import { trycatch } from '@/utils/trycatch'
import isDev from '@/utils/isDev'
import { DateTime } from 'luxon'
import { UserConected } from '@/interfaces/Comunications.interface'
import isMobile from '@/utils/isMobile'
// @ts-ignore
import { saveAs } from 'file-saver'

const state = {
  hideBeforeCloseDialog: false,
  drawer: false,
  drawerTab: 'tab-monitoreo',
  socket: null as Socket<any, any> | null,
  socketEvents: null as Socket<any, any> | null,
  socketChats: null as Socket<any, any> | null,
  configTab: null,
  currentTab: 'tab-Consola',
  timeOutId: 0,
  isConfigOpen: false,
  logs: {} as { [key: string]: [] },
  loaderDialog: {
    isLoader: false,
    dialogMessage: ''
  },
  geolocation: null as GeolocationPosition | null,
  strings: {
    companyName: '',
    SOCKET_URL: '',
    SS_API_URL: '',
    PEER_URL: '',
    PEER_HOST: '',
    ICE_SERVERS: [],
    STREAM_RTSP_PARSER: ''
  },
  isDev: isDev(),
  isMobile: isMobile(),
  timeoffset: DateTime.now().setZone(DateTime.local().zoneName).offset / 60
}

type State = typeof state;

const mutations = {
  SET_STATE (state: State, payload: typeof state) {
    Object.assign(state, payload)
  },
  SET_GEOLOCATION (state: State, payload: GeolocationPosition | null) {
    // @ts-ignore
    state.geolocation = payload
  },
  CHANGE_MONIT_TAB: (state: State, tab: string): void => {
    state.currentTab = tab
  },
  CHANGE_DRAWER_TAB: (state: State, tab: string): void => {
    state.drawerTab = tab
  },
  SET_CONFIG_DIALOG_STATE (state: State, changeDialog: boolean): void {
    state.isConfigOpen = changeDialog
  },
  SET_LOADER_DIALOG_STATE (state: State, loaderState: { isLoader?: boolean, dialogMessage?: string }): void {
    Object.assign(state.loaderDialog, loaderState)
  },
  ADD_LOG (state: State, log: { title: string; color: string; message: string; payload: any }) {
    const currKey = log.title.replaceAll(' ', '_')
    if (!state.logs[currKey]) {
      state.logs[currKey] = []
    }

    if (state.logs[currKey].length > 25) {
      state.logs[currKey].pop()
    }
    // @ts-ignore
    state.logs[currKey].unshift({ ...log, date: new Date().toUTCString() })
  },
  SET_SOCKET: (state: State, socket: Socket): void => {
    // @ts-ignore
    state.socket = socket
  },
  SET_STRINGS: (state: State, strings: typeof state.strings): void => {
    Object.assign(state.strings, strings)
  }
}

const actions = {
  downloadLogs ({ state, rootState }: ActionContext<State, string>) {
    const currentUTCDate = DateTime.now().toUTC().toJSON()
    // @ts-ignore
    const auth_data = JSON.parse(JSON.stringify(rootState.auth))
    delete auth_data.auth_token
    delete auth_data.prevAuth
    delete auth_data.user_data.us_config

    const logs = state.logs
    const context = {
      auth: auth_data,
      userAgent: navigator.userAgent,
      language: navigator.language,
      cookieEnabled: navigator.cookieEnabled,
      width: screen.width,
      height: screen.height,
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
      url: window.location.href,
      isMobile: isMobile(),
      referrer: document.referrer,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      currentDate: DateTime.utc().plus({ hours: state.timeoffset }).toJSON(),
      currentUTCDate
    };

    const fileToSave = new Blob([JSON.stringify({ context, logs })], {
      type: 'application/json'
    })
    saveAs(fileToSave, `logs-${currentUTCDate}.json`)
  },
  addLogWithError (context: ActionContext<State, string>, { title, color, error, message }: { error: any; title: string; color: string; message: string; }) {
    context.commit('ADD_LOG', { title, color, message, payload: serializeError(error) })
  },
  init: async (context: ActionContext<State, string>): Promise<void> => {
    const dev = isDev()
    await context.dispatch('initSocket')

    if (dev) console.time('Obteniendo datos init')
    const { data } = await context.dispatch('axios',
      {
        url: 'init',
        method: 'get'
      }
    )
    if (dev) console.timeEnd('Obteniendo datos init')

    if (dev) console.time('Almacenando datos init')
    await context.dispatch('auth/init', {}, { root: true })
    await context.commit('chat/SET_USERS_CONECTED', data.chat_users_conected, { root: true })
    await context.commit('comunications/SET_USERS_CONECTED', data.users_conected, { root: true })
    await context.commit('auth/SET_US_CONFIG', data.us_config, { root: true })
    await context.commit('geofences/SET_ROUTES_DESTINATIONS', data.route_destinations, { root: true })
    await context.commit('whatsapp/SET_MESSAGES', data.wmessages, { root: true })
    await context.commit('actualizer/SET_ACTUALIZER_NOTES', data.actualizer_notes, { root: true })
    await context.dispatch('reports/getReportData', { reports: data.reports.data }, { root: true })
    await context.dispatch('drivers/setConsoleDrivers', { drivers: data.drivers }, { root: true })
    await context.commit('binnacle/SET_STATE_DATA', data.binnacle, { root: true })
    await context.commit('steppers/SET_STEPPERS', data.steppers, { root: true })
    await context.commit('steppers/SET_STEPS', data.steps, { root: true })
    await context.commit('camera/SET_CAMERA_PROPERTIES', data.camera_properties, { root: true })
    await context.commit('manager/SET_STATE', { admin: { ...data.admin, ...data.horarios } }, { root: true })
    await context.dispatch('geofences/reloadConsoleGeofences', data.geofences, { root: true })
    await context.commit('chat/SET_CHATS', data.chats, { root: true })
    await context.dispatch('manager/saveLastCurrentPeriod', data.last_schedule_period, { root: true })
    await context.dispatch('manager/saveNearestSchedulePeriods', data.nearest_schedule_periods, { root: true })
    if (dev) console.timeEnd('Almacenando datos init')

    if (dev) console.time('Inicializando store')
    await Promise.all([
      context.dispatch('notes/getAllAvaliableNotes', { notes: data.notes.data }, { root: true }),
      context.dispatch('events/init', {}, { root: true }),
      context.dispatch('drivers/init', {}, { root: true }),
      context.dispatch('geofences/init', {}, { root: true }),
      context.dispatch('reports/init', {}, { root: true }),
      context.dispatch('console/init', {}, { root: true }),
      context.dispatch('notes/init', {}, { root: true }),
      context.dispatch('whatsapp/init', {}, { root: true }),
      context.dispatch('actualizer/init', {}, { root: true }),
      context.dispatch('binnacle/init', {}, { root: true }),
      context.dispatch('steppers/init', {}, { root: true }),
      context.dispatch('camera/init', {}, { root: true }),
      context.dispatch('comunications/init', {}, { root: true }),
      context.dispatch('chat/init', {}, { root: true }),
      context.dispatch('manager/initScheduleUserDone', {}, { root: true })
    ])
    if (dev) console.timeEnd('Inicializando store')

    if (context.rootGetters['auth/isRol'](USER_TYPE.ADMINISTRADOR, USER_TYPE.OPERACIONES)) {
      await context.dispatch('manager/init', {}, { root: true })
    }

    context.dispatch('connectSockets')
    // Conect to hikvision camera events notifications
    context.dispatch('hikvision/init', {}, { root: true })

    // Este interval permite actualzar los usuarios conectados al socket, tanto a la consola como a los chats
    setInterval(async () => {
      // @ts-ignore
      if (!context.rootState.auth.is_auth) return
      const users: UserConected[] = await context.dispatch('comunications/getUsersConected', {}, { root: true })
      if (users) context.commit('comunications/SET_USERS_CONECTED', users.filter(user => user.namespace !== '/Chat'), { root: true })
      if (users) context.commit('chat/SET_USERS_CONECTED', users.filter(user => user.namespace === '/Chat'), { root: true })
    }, 120000)
  },
  initSocket ({ getters, rootState, commit }: ActionContext<State, any>) {
    // @ts-ignore
    const id: string = rootState.auth.user_data.id_user
    // @ts-ignore
    const user_type_id: number = rootState.auth.user_data.user_type.id

    let namespace = ''
    switch (user_type_id) {
      case USER_TYPE.CLIENTE: {
        namespace = '/Cliente'
        break;
      }
      case USER_TYPE.CONDUCTOR: {
        namespace = '/Conductores'
        break;
      }
      default: {
        namespace = '/Monitoreo'
        break;
      }
    }

    const socket = io(`${getters.SOCKET_URL}${namespace}`, {
      autoConnect: false,
      query: { id }
    })

    commit('SET_SOCKET', socket)

    const socketEvents = io(`${getters.SOCKET_URL}/Eventos`, {
      autoConnect: false,
      query: { id }
    })

    commit('SET_STATE', { socketEvents })

    const socketChats = io(`${getters.SOCKET_URL}/Chat`, {
      autoConnect: false,
      query: { id }
    })

    commit('SET_STATE', { socketChats })
  },
  connectSockets (context: ActionContext<State, any>) {
    if (context.state.socket) context.state.socket.connect();
    if (context.state.socketEvents) context.state.socketEvents.connect();
    if (context.state.socketChats) context.state.socketChats.connect();
  },
  async axios ({ dispatch, rootState, rootGetters }: ActionContext<State, any>, options: AxiosRequestConfig): Promise<any> {
    try {
      // @ts-ignore
      const instance = axios.create({
        baseURL: rootGetters['sys/SS_API_URL'] || '/',
        headers: {
          // @ts-ignore
          Authorization: rootState.auth.auth_token
        }
      })
      // @ts-ignore
      const data = await instance(options)

      return data
    } catch (error) {
      console.error(error)
      // @ts-ignore
      if (error?.response?.status === 403) {
        await dispatch('auth/closeSession', {}, { root: true })
      }
      // @ts-ignore
      if (error?.response?.status === 423) {
        dispatch('showNotificationMessage', {
          title: 'Cuenta temporalmente deshabilitada',
          color: 'error'
        })
        await dispatch('auth/closeSession', {}, { root: true })
      }
      throw error
    }
  },
  changeMonitTab: (context: ActionContext<State, string>, tab: string): void => {
    context.commit('CHANGE_MONIT_TAB', tab)
  },
  changeDrawerTab: (context: ActionContext<State, string>, tab: string): void => {
    context.commit('CHANGE_DRAWER_TAB', tab)
  },
  toogleDrawer: (context: ActionContext<State, string>, action: boolean): void => {
    context.commit('SHOW_HIDE_DRAWER', action)
  },
  toogleConfigDialog (context: ActionContext<State, string>): void {
    context.commit('SET_CONFIG_DIALOG_STATE', !(context.state.isConfigOpen))
  },
  showNotificationMessage (_: ActionContext<State, string>, notPayload: {
    id?: number,
    title: string,
    color: string,
    icon?: string,
    text?: string,
    duration?: number,
    data?: any
    group?: string
  }): void {
    const properties = {
      id: notPayload.id,
      title: notPayload.title,
      icon: notPayload.icon || 'mdi-exclamation',
      text: notPayload.text || '',
      type: notPayload.color || 'info',
      duration: notPayload.duration || 9000,
      group: notPayload.group || 'notification',
      speed: 100,
      data: notPayload.data
    }

    // @ts-ignore
    this._vm.$notify(properties)
  },
  showMessageAlert ({ dispatch }: ActionContext<State, string>, message: string) {
    dispatch('showNotificationMessage', {
      title: message,
      color: 'error',
      duration: 2000
    })
  },
  showMessageSuccess ({ dispatch }: ActionContext<State, string>, message: string) {
    dispatch('showNotificationMessage', {
      title: message,
      color: 'success',
      duration: 2000
    })
  },
  showNavigatorMessage (context: ActionContext<State, string>, notPayload: {
    title: string,
    body: string,
    icon: string,
    sound: string
    callback?: () => void
  }): void {
    if (window.Notification && Notification.permission !== 'denied') {
      // @ts-ignore
      const notification = new Notification(notPayload.title, {
        body: notPayload.body,
        icon: notPayload.icon,
        silent: true
      })

      if (notPayload.callback) {
        notification.addEventListener('click', notPayload.callback)
      }
    }
  },
  async requestNotificationPermission (context: ActionContext<State, string>): Promise<void> {
    let timeoutId = null;
    try {
      timeoutId = setTimeout(() => { throw new Error('Timeout requestNotificationPermission') }, 7500)
      const result = await navigator.permissions.query({ name: 'notifications' });
      if (result.state === 'granted') {
        context.dispatch('showNotificationMessage', { title: 'Se han activado las notificaciones', color: 'plum' })
      } else {
        context.dispatch('showNotificationMessage', { title: 'Notificaciones no activadas', color: 'error' })
      }
    } catch (error) {
      context.dispatch('showNotificationMessage', { title: 'No se puede acceder a las notificaciones en el navegador', color: 'error' })
    } finally {
      // @ts-ignore
      clearTimeout(timeoutId)
    }
  },
  async requestGeolocationPermission (context: ActionContext<State, string>): Promise<void> {
    let timeoutId = null;
    try {
      timeoutId = setTimeout(() => { throw new Error('Timeout requestGeolocationPermission') }, 7500)
      const result = await navigator.permissions.query({ name: 'geolocation' });
      clearTimeout(timeoutId)

      if (result.state === 'granted') {
        context.dispatch('showNotificationMessage', { title: 'Coordenadas de mapa obtenidas', color: 'plum' })
        navigator.geolocation.getCurrentPosition(payload => {
          context.commit('SET_GEOLOCATION', payload)
        }, () => {
          context.dispatch('showNotificationMessage', { title: 'Coordenadas de mapa no obtenidas', color: 'error' })
        })
      } else {
        context.dispatch('showNotificationMessage', { title: 'Coordenadas de mapa no obtenidas', color: 'error' })
      }
    } catch (error) {
      context.dispatch('showNotificationMessage', { title: 'No se pudieron acceder a las corrdenadas en el navegador', color: 'error' })
      console.error(error)
    } finally {
      // @ts-ignore
      clearTimeout(timeoutId)
    }
  },
  async playSound (_: ActionContext<State, string>, audioPayload: { url?: string, type: 'done' | 'alert' | 'zumbido' | 'message' | 'message-low' | 'error' }): Promise<HTMLAudioElement | void> {
    try {
      if (audioPayload) {
        if (audioPayload.url) {
          const audio = new Audio(audioPayload.url)
          audio.play()
          return audio
        } else if (audioPayload.type) {
          switch (audioPayload.type) {
            case 'alert':
              await new Audio('/audio/alert.mp3').play()
              break
            case 'done':
              await new Audio('/audio/done.mp3').play()
              break
            case 'zumbido':
                await new Audio('/audio/zumbido.wav').play()
                break
            case 'message':
              await new Audio('/audio/message.mp3').play()
              break
            case 'message-low':
              await new Audio('/audio/message-low.mp3').play()
              break
            case 'error':
              await new Audio('/audio/error.mp3').play()
              break
            default:
              await new Audio('/audio/notify.wav').play()
              break
          }
        } else {
          await new Audio('/audio/notify.wav').play()
        }
      } else {
        await new Audio('/audio/notify.wav').play()
      }
    } catch (error) {
      await new Audio('/audio/notify.wav').play()
      console.error(error)
    }
  },
  async sendMessageNotification (context: ActionContext<State, string>, messagePayload: { title: string, text?: string }): Promise<void> {
    await context.dispatch(
      'sys/axios',
      {
        url: 'send_message_notification',
        method: 'post',
        data: messagePayload
      },
      { root: true }
    )
  },
  socket_messageNotification (context: ActionContext<State, string>, messagePayload: { title: string, text?: string }): void {
    // @ts-ignore
    context.dispatch('showNotificationMessage', messagePayload)
  },
  closeToast: (context: ActionContext<State, string>): void => {
    context.commit('CHANGE_TOAST_STATE', { isVisible: false })
  },
  setLoaderDialogState (context: ActionContext<State, string>, loaderState: { isLoader?: boolean, dialogMessage?: string }): void {
    context.commit('SET_LOADER_DIALOG_STATE', loaderState)
  },
  async copyMessage ({ dispatch }: ActionContext<State, string>, msPayload: { message: string, messageCalback?: CallableFunction }): Promise<void> {
    try {
      await navigator.clipboard.writeText(msPayload.message)
      if (msPayload.messageCalback && isFunction(msPayload.messageCalback)) {
        if (isPromise(msPayload.messageCalback)) {
          await msPayload.messageCalback()
        } else {
          msPayload.messageCalback()
        }
      }
    } catch (error) {
      await dispatch('showNotificationMessage', {
        title: 'A ocurrido un error al copiar el mensaje',
        color: 'error',
        duration: -1
      });
      console.error(error)
    }
  },
  cleanNotifications (_: ActionContext<State, string>) {
    // @ts-ignore
    this._vm.$notify({
      clean: true,
      group: 'notification'
    });
  },
  async copyToast ({ dispatch }: ActionContext<State, string>, { message }: { message: string }) {
    try {
      await dispatch('showNotificationMessage', {
        title: 'Copiando mensaje...',
        color: 'info',
        duration: -1
      });
      await dispatch('copyMessage', { message });
      // @ts-ignore
      this._vm.$notify({
        clean: true,
        group: 'notification'
      });
      await dispatch('showNotificationMessage', {
        title: 'Copiado!',
        color: 'success',
        duration: 5000
      });
    } catch (error) {
      await dispatch('showNotificationMessage', {
        title: 'A ocurrido un error al copiar el mensaje',
        color: 'error',
        duration: -1
      });
      console.log(error);
    }
  },
  async createReportItemByPlataformID (context: ActionContext<State, string>, data: { id: any; pType: PLATAFORM_TYPE }): Promise<void> {
    switch (data.pType) {
      case PLATAFORM_TYPE.WIALON: {
        return await context.dispatch('wialon/createReportItemByPlataformID', data, { root: true })
      }
      case PLATAFORM_TYPE.MAPON: {
        return await context.dispatch('mapon/createReportItemByPlataformID', data, { root: true })
      }
      case PLATAFORM_TYPE.TRACKSOLID: {
        return await context.dispatch('tracksolid/createReportItemByPlataformID', data, { root: true })
      }
    }
  },
  async sendZumbido  ({ dispatch }: ActionContext<State, string>, payload: { sid: string; id_user_type: number }) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'send_zumbido',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'SEND_ZUMBIDO', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  makeZumbido (context: ActionContext<State, string>) {
    if (navigator.vibrate) { // Comprobamos si el navegador soporta la API de vibración
        trycatch(() => navigator.vibrate(1000), null) // Hace vibrar el dispositivo durante 1000 milisegundos
    }

    context.dispatch('playSound', {
      type: 'zumbido'
    })
    const mainElement = document.getElementById('mainElement');
    if (!mainElement) return

    mainElement.classList.add('zumbido');

    // Esto es necesario para resetear la animación después de que se completa
    mainElement.addEventListener('animationend', function () {
      mainElement.classList.remove('zumbido');
    }, { once: true });
  },
  isTabFocused (context: ActionContext<State, string>, { tab }: { tab: string }) {
    return context.state.currentTab === tab
  }
}

const getters = {
  viewportWidth () {
    return (porcentage: number) => {
      return window.innerWidth * parseFloat(`0.${porcentage}`)
    }
  },
  viewportHeight () {
    return (porcentage: number) => {
      return window.innerHeight * parseFloat(`0.${porcentage}`)
    }
  },
  companyName (state: State) {
    return state.strings.companyName
  },
  SOCKET_URL (state: State) {
    return state.strings.SOCKET_URL
  },
  SS_API_URL (state: State) {
    return state.strings.SS_API_URL
  },
  STREAM_RTSP_PARSER (state: State) {
    return state.strings.STREAM_RTSP_PARSER
  },
  geolocation (state: State) {
    return state.geolocation
  },
  dev (state: State) {
    return state.isDev
  },
   socketChatId (state: State) {
    return state.socketChats?.id
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
