import { ActionContext } from 'vuex';
import chat_item from './chat_item';
import chat_message from './chat_message';
import chat_user from './chat_user';
import { State } from '../state';
import { AxiosRequestConfig } from 'axios';
import { IChat, IChatBotCase, IChatGroup, IChatMessage, IChatSchemaSimple, IChatUser } from '@/store/interfaces/Chat';
import ChatItem from '@/store/packages/Chat/ChatItem';
import { UserConected } from '@/interfaces/Comunications.interface';
import chat_file from './chat_file';
import chat_notifications from './chat_notifications';
import chat_bot_cases from './chat_bot_cases';
import chat_bot_tools from './chat_bot_tools';
import chat_log_data from './chat_log_data';
import { Socket } from 'socket.io-client';
import chat_reports from './chat_reports';

export default {
  async init ({ dispatch, state, rootGetters }: ActionContext<State, any>) {
    await dispatch('subscribeToSocketChatHandlers')

    // El debounce es para retrazar la ejecusion de esta funcion cada vez que el chat cambie
    // @ts-ignore
    this._vm.$watch(() => state.currChat, async (chat: ChatItem | null) => {
      // Si no hasy chay o no hay ultimo mensaje, se retorna
      if (!chat || !chat.lastChatMessage?.id_chat_message || chat.unreadedChatMessages === 0) return

      const id_chat_user = chat.getOwnChatUserId()
      // Si no se encuentra el propio id_chat_user, se retorna
      if (!id_chat_user) return

      try {
        const chatUsersData: { id_chat_user: number; id_user: number; chu_last_seen_message_id: number }[] = await dispatch('updateLastUserMessageSeenAndGetUsersLastConection', {
          id_chat: chat.id_chat,
          id_chat_message: chat.lastChatMessage.id_chat_message,
          id_chat_user,
          id_user: rootGetters['auth/userId']
        })

        // Actualizo el status de los usuarios del chat
        chat.updateChatUsersByIChatUsers(chatUsersData)
        // Actualizo el status de los mensajes tambien
        chat.updateUserMessagesStatus()
        // Actualizo los mensajes no leidos
        chat.updateUnreadedChatMessages()
      } catch (error) {
        console.error(error)
      }
    })

    // Cada 5 minutos se actualiza el estado de los chats en caso de que el socket no funcione correctamente
    // En caso de que el usuario tenga mensajes no leidos, se reproduce un sonido
    const FIVE_MINUTES_IN_MS = 5 * 60 * 1000
    setInterval(async () => {
      await dispatch('rehidratateChats')
      const userHaveUnreadedMessages: boolean = await dispatch('userHaveUnreadedMessages')
      if (userHaveUnreadedMessages) {
        dispatch('sys/playSound', { type: 'message' }, { root: true })
      }
    }, FIVE_MINUTES_IN_MS)
  },
  async subscribeToSocketChatHandlers ({ dispatch, rootState }: ActionContext<State, any>) {
    // @ts-ignore
    const socketChat: Socket | null = rootState.sys.socketChats
    if (!socketChat) return

    socketChat.on('connect', () => dispatch('onSocketConnect', {}))
    // Si llega un nuevo mensaje, lo añade al correspondiente chat
    socketChat.on('chat_created', (data: IChat) => dispatch('socket_ChatCreated', data))
    socketChat.on('chat_updated', (data: IChatSchemaSimple) => dispatch('socket_ChatUpdated', data))
    socketChat.on('chat_thumbnail_updated', (data: { chat: IChatSchemaSimple; message: IChatMessage }) => dispatch('socket_ChatThumbnailUpdated', data))
    socketChat.on('chat_deleted', (data: number) => dispatch('socket_ChatDeleted', data))
    socketChat.on('chat_group_binded', (data: IChatGroup) => dispatch('socket_ChatGroupBinded', data))
    socketChat.on('chat_group_unbinded', (data: IChatGroup) => dispatch('socket_ChatGroupUnBinded', data))
    socketChat.on('chat_user_updated', (data: { id_chat: number; chat_user: IChatUser }) => dispatch('socket_ChatUserUpdated', data))
    socketChat.on('chat_users_added', (data: { id_chat: number; chat_users: IChatUser[]; message: IChatMessage }) => dispatch('socket_ChatUserAdded', data))
    socketChat.on('chat_users_removed', (data: { id_chat: number; chat_users: IChatUser[]; message: IChatMessage }) => dispatch('socket_ChatUsersRemoved', data))
    socketChat.on('chat_users_updated', (data: { id_chat: number; chat_users: IChatUser[] }) => dispatch('socket_ChatUsersUpdated', data))
    socketChat.on('chat_message_added', (data: IChatMessage) => dispatch('socket_chatMessageAdded', data))
    socketChat.on('chat_messages_added', (data: { messages: IChatMessage[]; id_chat_item: number }) => dispatch('socket_chatMessagesAdded', data))
    socketChat.on('chat_message_updated', (data: IChatMessage) => dispatch('socket_chatMessageUpdated', data))
    socketChat.on('chat_message_deleted', (data: { id_chat: number; id_chat_message: number }) => dispatch('socket_chatMessageDeleted', data))
    socketChat.on('chat_user_last_seen_changed', (data: { id_chat: number; id_chat_message: number; id_user: number }) => dispatch('socket_chatUserLastSeenChanged', data))
    socketChat.on('chat_bot_unknown_question', (data: { id_chat: number; chm_message: string; chat_bot_case: IChatBotCase | null }) => dispatch('socket_chatBotUnknownQuestions', data))
    socketChat.on('chat_bot_error', (data: { id_chat: number; payload: any; }) => dispatch('socket_chatBotError', data))
    socketChat.on('chat_bot_unknown_destinations', (data: { id_chat_item: number; payload: any; }) => dispatch('socket_chatBotUnknownDestinations', data))
    socketChat.on('chat_file_attached_added', (data: any) => dispatch('socket_chatFileAttachedAdded', data))
    socketChat.on('chat_file_attached_error', (data: any) => dispatch('socket_chatFileAttachedError', data))
    socketChat.on('chat_file_attached_deleted', (data: any) => dispatch('socket_chatFileAttachedDeleted', data))
    socketChat.on('chat_file_attached_updated', (data: any) => dispatch('socket_chatFileAttachedUpdated', data))
    socketChat.on('user_conected', (data: UserConected) => dispatch('socket_userConected', data))
    socketChat.on('user_disconected', (data: UserConected) => dispatch('socket_userDisconected', data))
  },
  // Reemplazo el axios que uso del modulo sys para añadir el sid del socket del chat
  // Esto es para evitar que el que emite un evento no reciva sus mismos eventos
  async axios ({ dispatch, rootState }: ActionContext<State, any>, options: AxiosRequestConfig) {
    const data = await dispatch('sys/axios', { ...options, headers: { chatSID: rootState.sys.socketChats.id, ...options.headers } }, { root: true })
    return data
  },
  // @ts-ignore
  forceOpenChat ({ commit, state }: ActionContext<State, any>, { chat, id_chat_message }: { chat: ChatItem; id_chat_message?: number }) {
    commit('SET_STATE', { drawer: true })
    commit('SET_CURR_CHAT', chat)
    if (id_chat_message) {
      // @ts-ignore
      this._vm.$nextTick(() => {
        setTimeout(() => {
          state.currChatComponent.$vuetify.goTo(`#chat-message-${id_chat_message}`, {
            duration: 300,
            offset: 0,
            easing: 'easeInOutCubic',
            container: '.chat_body_container'
          })
        }, 500)
      })
    }
  },
  hideDraweChats ({ commit }: ActionContext<State, any>) {
    commit('SET_STATE', { drawer: false })
    commit('SET_CURR_CHAT', null)
  },
  async onSocketConnect ({ dispatch }: ActionContext<State, any>) {
    await dispatch('rehidratateChats', {})
    await dispatch('susbscribeToAllChatEvents', {})
  },
  ...chat_item,
  ...chat_message,
  ...chat_user,
  ...chat_file,
  ...chat_notifications,
  ...chat_reports,
  ...chat_bot_cases,
  ...chat_bot_tools,
  ...chat_log_data
}
