import { State } from '..'

export default {
  currScheduleUserPeriodDone: (state: State) => {
    return state.schedulestate.currScheduleUserPeriodDone
  },
  currFormatedLabelTime: (state: State) => {
    return state.schedulestate.currFormatedLabelTime
  },
  areUserPeriodTypes (state: State) {
    return state.schedulestate.scheduleAvaliableUserPeriodsTypes.length
  },
  avaliableScheduleUserPeriods: (state: State) => {
    if (!state.schedulestate.currScheduleUserPeriodDone) return state.schedulestate.scheduleAvaliableUserPeriodsTypes

    return state.schedulestate.scheduleAvaliableUserPeriodsTypes.filter(
      period => period.id_schedule_turn_weekday_period_type !== state.schedulestate.currScheduleUserPeriodDone?.id_schedule_turn_weekday_period_type
    )
  }
}
