export default function getGeographicMidpoint (points: { lat: number; lng: number }[]): { lat: number; lng: number } {
  if (points.length === 0) {
      throw new Error('No se proporcionaron puntos.');
  }

  let sumX = 0;
  let sumY = 0;
  let sumZ = 0;

  for (const point of points) {
      // Convertir latitud y longitud de grados a radianes
      const latRad = degreesToRadians(point.lat);
      const lngRad = degreesToRadians(point.lng);

      // Convertir a coordenadas cartesianas
      const x = Math.cos(latRad) * Math.cos(lngRad);
      const y = Math.cos(latRad) * Math.sin(lngRad);
      const z = Math.sin(latRad);

      // Sumar las coordenadas
      sumX += x;
      sumY += y;
      sumZ += z;
  }

  const totalPoints = points.length;

  // Calcular el promedio de las coordenadas
  const avgX = sumX / totalPoints;
  const avgY = sumY / totalPoints;
  const avgZ = sumZ / totalPoints;

  // Convertir de coordenadas cartesianas a latitud y longitud
  const lng = Math.atan2(avgY, avgX);
  const hyp = Math.sqrt(avgX * avgX + avgY * avgY);
  const lat = Math.atan2(avgZ, hyp);

  // Convertir de radianes a grados
  return {
      lat: radiansToDegrees(lat),
      lng: radiansToDegrees(lng)
  };
}

// Función para convertir grados a radianes
function degreesToRadians (degrees: number): number {
  return degrees * (Math.PI / 180);
}

// Función para convertir radianes a grados
function radiansToDegrees (radians: number): number {
  return radians * (180 / Math.PI);
}
