/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReportData, ReportLayout } from '@/store/interfaces/ReportModules';
import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE';
import RIWialon from '@/store/packages/ReportItem/RIWialon';
import getReportTimeUnit from '@/utils/wialonSDK/getReportTimeUnit';
import ReportItemData from '@/store/packages/ReportItem/ReportItemData';
import RIMapon from '@/store/packages/ReportItem/RIMapon';
import MaponSDK from '@/submodules/maponjssdk/dist';
import RICommon from '@/store/packages/ReportItem/RICommon';
import { ActionContext } from 'vuex';
import TrackSolidSDK from '@/submodules/tracksolidjavacriptsdk/dist';
import RITrackSolid from '@/store/packages/ReportItem/RITrackSolid';
import { serializeError } from 'serialize-error';
import createRID from '@/utils/consoleUtils/createRID';
import { trycatch } from '@/utils/trycatch';
import getSearchItems from '@/utils/wialonSDK/getSearchItems';
import updateDataFlags from '@/utils/wialonSDK/updateDataFlags';
import { forEachLimit } from 'modern-async';
import getNavigatorHardwareConcurrency from '@/utils/getNavigatorHardwareConcurrency';

export interface Layout {
  x: number
  y: number
  w: number
  h: number
  i: string
}

export const reportBreakpoints = {
  cols: {
    xl: 4,
    lg: 3,
    md: 2,
    sm: 1
  },
  breakpoints: {
    xl: 1849,
    lg: 1449,
    md: 849,
    sm: 1
  },
  rowHeight: 70
};

const getCurretLayoutColumns = (): number => {
  const currentClientWidth = document.documentElement.clientWidth;
  const autoBreakpoint = Math.round(currentClientWidth / 480);
  return autoBreakpoint;
};

const getWialonItems = async (reportData: ReportData[], context: ActionContext<unknown, unknown>) => {
  try {
    // @ts-ignore
    const sess = wialon.core.Session.getInstance();
    const wialonRIIDS: string[] = reportData.reduce((prev, curr) => {
      if (curr.id_plataform === PLATAFORM_TYPE.WIALON) {
        const ri_wialon_ids = curr.ri_items.map((ri) => {
          const ids = [];
          if (ri.ri_wialon_id) ids.push(ri.ri_wialon_id);
          if (ri.ri_back_wialon_id) ids.push(ri.ri_back_wialon_id);
          return ids;
        });

        // @ts-ignore
        return prev.concat(ri_wialon_ids.flat());
      } else {
        return prev;
      }
    }, []);

    const unit = sess.getItem(wialonRIIDS.at(0))
    console.log(unit)
    const propValueMask = wialonRIIDS.length ? wialonRIIDS.join(',') : 'avl_unit'

    await updateDataFlags('col', wialonRIIDS, 1 + 4 + 32 + 1024 + 4096 + 512 + 524288, 1)
    // await trycatch(async () => await getReportTimeUnit(sess), null)
    // @ts-ignore
    // const wialon_items: any[] = await sess.getItems(propValueMask)
    const wialon_items: any[] = await getSearchItems(sess, { flags: 1 + 4 + 32 + 1024 + 4096 + 512 + 524288, itemsType: 'avl_unit', propValueMask })
    console.log(wialon_items)
    return wialon_items
  } catch (error) {
    console.error(error)
    context.commit('sys/ADD_LOG', { title: 'UNABLE_TO_GET_WIALON_DATA', color: 'warning', message: '', payload: serializeError(error) }, { root: true })
    return []
  }
}

const getTrackSolidItems = async (reportData: ReportData[], context: ActionContext<unknown, unknown>) => {
  try {
    // @ts-ignore
    const sdk: TrackSolidSDK = context.rootState.tracksolid.sdk
    const trakcsolidRIIDS: string[] = reportData.reduce((prev, curr) => {
      if (curr.id_plataform === PLATAFORM_TYPE.TRACKSOLID) {
        // @ts-ignore
        const ri_wialon_ids = curr.ri_items.map((ri) => {
          const ids = [];
          if (ri.ri_wialon_id) ids.push(ri.ri_wialon_id);
          if (ri.ri_back_wialon_id) ids.push(ri.ri_back_wialon_id);
          return ids;
        });

        // @ts-ignore
        return prev.concat(ri_wialon_ids.flat());
      } else {
        return prev;
      }
    }, []);

    if (trakcsolidRIIDS.length <= 0) return []

    const items = await sdk.item.DeviceItem.getItemsByIds({ imeis: trakcsolidRIIDS })
    return items
  } catch (error) {
    console.error(error)
    context.commit('sys/ADD_LOG', { title: 'UNABLE_TO_GET_TRACKSOLID_DATA', color: 'warning', message: '', payload: serializeError(error) }, { root: true })
    return []
  }
}

const getMaponItems = async (reportData: ReportData[], context: ActionContext<unknown, unknown>) => {
  try {
    // @ts-ignore
    const maponRIIDS: string[] = reportData.reduce((prev, curr) => {
      if (curr.id_plataform === PLATAFORM_TYPE.MAPON) {
        const ri_wialon_ids = curr.ri_items.map((ri) => {
          const ids = [];
          if (ri.ri_wialon_id) ids.push(ri.ri_wialon_id);
          if (ri.ri_back_wialon_id) ids.push(ri.ri_back_wialon_id);
          return ids;
        });

        // @ts-ignore
        return prev.concat(ri_wialon_ids.flat());
      } else {
        return prev;
      }
    }, []);

    if (maponRIIDS.length <= 0) return []

    // @ts-ignore
    const items = await MaponSDK.actions.AUnit.list({ include: ['ignition', 'drivers'] })
    // const items = await MaponSDK.actions.AUnit.getByIds(maponRIIDS, { include: ['ignition', 'drivers'] })
    return items
  } catch (error) {
    console.error(error)
    context.commit('sys/ADD_LOG', { title: 'UNABLE_TO_GET_MAPON_DATA', color: 'warning', message: '', payload: serializeError(error) }, { root: true })
    return []
  }
}

// Each h (height) is equals to 50px
export default async (
  reportData: ReportData[],
  context: ActionContext<unknown, unknown>
): Promise<{ reportLayoutData: ReportLayout[]; layout: Layout[] }> => {
  const layout: Layout[] = [];
  const reportLayoutData: ReportLayout[] = [];
  const concurrencyHardware = getNavigatorHardwareConcurrency()

  const haveWialon = await context.dispatch('auth/havePlataform', PLATAFORM_TYPE.WIALON, { root: true })
  const tracksolidItems = await getTrackSolidItems(reportData, context)
  const maponItems = await getMaponItems(reportData, context)
  // const wialonItems = await getWialonItems(reportData, context)

  let sess: { getItem: (arg0: string) => any; } | null = null
  if (haveWialon) {
    // @ts-ignore
    sess = wialon.core.Session.getInstance();
    await trycatch(async () => await getReportTimeUnit(sess), null)
  }

  await forEachLimit(reportData, async (unitData, index) => {
    if (!unitData.ri_items.length) return;
    const reportItemsData: ReportItemData<unknown, unknown>[] = [];

    await forEachLimit(unitData.ri_items, async (report_item) => {
      switch (unitData.id_plataform) {
        case PLATAFORM_TYPE.WIALON: {
          // const unitItem = wialonItems.find(obj => `${obj._id}` === `${report_item.ri_wialon_id}`);
          // const backItem = wialonItems.find(obj => `${obj._id}` === `${report_item.ri_back_wialon_id}`);
          // const unitItem = await trycatch(() => sess.getItem(report_item.ri_wialon_id), null)
          // const backItem = await trycatch(() => sess.getItem(report_item.ri_back_wialon_id), null)
          const [unitItem, backItem] = await Promise.all([
            // @ts-ignore
            trycatch(() => sess.getItem(report_item.ri_wialon_id), null),
            // @ts-ignore
            trycatch(() => sess.getItem(report_item.ri_back_wialon_id), null)
          ])
          const rid = createRID({
            unitItem,
            backItem,
            // @ts-ignore
            BaseRID: RIWialon,
            report_item,
            reportItemProperties: {
              id_group: unitData.id_group,
              id_report_group: unitData.id_report_group,
              type: unitData.id_plataform,
              gp_wialon_id: unitData.gp_wialon_id
            }
          })
          reportItemsData.push(rid);
          break;
        }
        case PLATAFORM_TYPE.MAPON: {
          const unitItem = maponItems.find(mi => mi.unit_id === parseInt(report_item.ri_wialon_id));
          const backItem = maponItems.find(mi => mi.unit_id === parseInt(report_item.ri_back_wialon_id));
          const rid = createRID({
            unitItem,
            backItem,
            // @ts-ignore
            BaseRID: RIMapon,
            report_item,
            reportItemProperties: {
              id_group: unitData.id_group,
              id_report_group: unitData.id_report_group,
              type: unitData.id_plataform,
              gp_wialon_id: unitData.gp_wialon_id
            }
          })
          reportItemsData.push(rid);
          break;
        }
        case PLATAFORM_TYPE.TRACKSOLID: {
          const unitItem = tracksolidItems.find(tsI => tsI.imei === report_item.ri_wialon_id);
          const backItem = tracksolidItems.find(tsI => tsI.imei === report_item.ri_back_wialon_id);
          const rid = createRID({
            unitItem,
            backItem,
            // @ts-ignore
            BaseRID: RITrackSolid,
            report_item,
            reportItemProperties: {
              id_group: unitData.id_group,
              id_report_group: unitData.id_report_group,
              type: unitData.id_plataform,
              gp_wialon_id: unitData.gp_wialon_id
            }
          })
          reportItemsData.push(rid);
          break;
        }
        default: {
          const ri_data = new RICommon(report_item, {
            id_group: unitData.id_group,
            id_report_group: unitData.id_report_group,
            type: PLATAFORM_TYPE.CONSOLA,
            gp_wialon_id: unitData.gp_wialon_id,
            unitItem: null
          });
          reportItemsData.push(ri_data);
          break;
        }
      }
    }, Math.ceil(concurrencyHardware / 4))

    unitData.ri_items = reportItemsData;
    const x = index % getCurretLayoutColumns();
    const y = 0;
    const w = 1;
    const h = unitData.ri_items.length + 1;
    const i = `${index}`;
    layout.push({
      x,
      y,
      w,
      h,
      i
    });
    reportLayoutData.push({
      x,
      y,
      w,
      h,
      i,
      unitData
    });
  }, Math.ceil(concurrencyHardware / 4))

  return { reportLayoutData, layout };
};
