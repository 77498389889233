import Vue from 'vue'
import { State } from './state'

export default {
  /**
   * Returns true if the state.unitsAnaliticsData object is empty.
   * Useful for avoiding the display of the analysis of units when there are no units to analyze.
   * @param state
   * @returns {boolean}
   */
  isAnaliticsObjEmpty: (state: State): boolean => {
    return Object.keys(state.unitsAnaliticsData).length === 0
  },
  /**
   * Getter que devuelve un objeto con las geocercas seleccionadas en el mapa,
   * con el id de la geocerca como clave y el valor es el mismo id.
   * @param state
   * @returns {Record<string, string>}
   */
  currentSelectedGeofences: (state: State): Record<string, string> => {
    const selectedGeofences = {} as Record<string, string>
    let currGeofence = null
    let currGeofenceId = ''
    for (let i = 0; i < state.selectedGeofences.length; i++) {
      currGeofence = state.selectedGeofences[i]
      currGeofenceId = `${currGeofence.id}${currGeofence.pType}`
      Vue.set(selectedGeofences, currGeofenceId, currGeofenceId)
    }
    return selectedGeofences
  }
}
