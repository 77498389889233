import { Geofence, ResourceGeofences } from '@/store/interfaces/Geofences'
import AIMapon from '@/store/packages/Actualizer/AIMapon'
import RMapon from '@/store/packages/Actualizer/RMapon'
import MaponSDK from '@/submodules/maponjssdk'
import { ActionContext } from 'vuex'
import { PLATAFORM_TYPE } from '../../catalogs/PLATAFORM_TYPE'
import { State } from './state'
import ActualizerPlataform from '../../packages/Actualizer/ActualizerPlataform'
import { Alert } from '@/submodules/maponjssdk/dist/interfaces/Alert.interface'
import AUnit from '@/submodules/maponjssdk/dist/classes/actions/AUnit.action'
import ResourceNotification from '@/store/interfaces/ResourceNotification'
import Driver from '@/store/interfaces/Driver'
import { serializeError } from 'serialize-error'
import RIMapon from '@/store/packages/ReportItem/RIMapon'
import { GEOFENCES_TYPE } from '@/store/catalogs/GEOFENCES_TYPES'
import { trycatch } from '@/utils/trycatch'

export default {
  async init ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      const token = await dispatch('auth/getToken', PLATAFORM_TYPE.MAPON, {
        root: true
      })
      // @ts-ignore
      const sdk = new MaponSDK({ token, intervalTime: 8500 })
      commit('SET_SDK', sdk)
      await Promise.all([dispatch('getGeofences'), dispatch('getDrivers'), dispatch('getResourcesActualizer')])
    } catch (error) {
      commit('sys/ADD_LOG', { title: 'MAPON', color: 'error', message: 'SDK_INIT_ERROR', payload: serializeError(error) }, { root: true })
      console.error(error)
    }
  },
  async getGeofences ({ commit, rootState }: ActionContext<State, string>) {
    const objects = await MaponSDK.actions.AObjects.list()
    const geofences: Geofence[] = objects.map(geo => ({
      pType: PLATAFORM_TYPE.MAPON,
      n: geo.name,
      id: geo.id,
      b: {
        cen_x: 0,
        cen_y: 0,
        max_x: 0,
        max_y: 0,
        min_x: 0,
        min_y: 0
      },
      c: 0,
      ct: 0,
      d: '',
      e: 0,
      f: 0,
      i: 0,
      libId: 0,
      mt: 0,
      path: '',
      t: GEOFENCES_TYPE.POLYGON,
      w: 0,
      data: geo,
      resourceId: PLATAFORM_TYPE.MAPON
    }))

    const resGeofences: ResourceGeofences = {
      id: PLATAFORM_TYPE.MAPON,
      pType: PLATAFORM_TYPE.MAPON,
      inputSearch: '',
      name: 'MAPON_GEOFENCES',
      auxGeofences: geofences,
      geofences
    }

    // @ts-ignore
    const index = rootState.geofences.geofences.findIndex((geo: ResourceGeofences) => geo.pType === PLATAFORM_TYPE.MAPON)

    if (index !== -1) {
      commit('geofences/UPDATE_GEOFENCES', { geofences: resGeofences, index }, { root: true })
    } else {
      commit('geofences/PUSH_GEOFENCES', resGeofences, { root: true })
    }

    commit('SET_OBJECTS', objects)
  },
  getObject ({ state }: ActionContext<State, string>, id: number) {
    const object = state.objects.find(obj => obj.id === id)
    return object
  },
  getObjectsByIds ({ state }: ActionContext<State, string>, ids: number[]) {
    const objects = state.objects.filter(obj => ids.includes(obj.id))
    return objects
  },
  async getResourcesActualizer ({ commit }: ActionContext<State, string>) {
    const units = await MaponSDK.actions.AUnit.list({}, { raw: true })
    const resourceItem = new RMapon(null, 0)
    const maponActualizer = new ActualizerPlataform({ name: 'MAPON', resourcesItem: [resourceItem] })
    for (let i = 0; i < units.length; i++) {
      const unit = units[i]
      resourceItem.addUnit(new AIMapon(unit))
    }
    commit('actualizer/PUSH_RESOURCES_ACTUALIZER', maponActualizer, { root: true })
  },
  async getDrivers ({ commit }: ActionContext<State, string>) {
    const drivers = await MaponSDK.actions.ADriver.list()
    // @ts-ignore
    const driversFormated: Driver[] = drivers.map(drv => ({
      n: drv.getFullName(),
      id: drv.id,
      p: drv.phone,
      plataform: PLATAFORM_TYPE.MAPON
    }))
    commit('drivers/SET_DRIVERS', { drivers: { 0: { 0: driversFormated } }, pType: PLATAFORM_TYPE.MAPON }, { root: true })
  },
  initNotifications ({ dispatch, rootState }: ActionContext<State, string>, state: boolean) {
    const callback = async (alert: Alert) => {
      // @ts-ignore
      const isDev: boolean = rootState.sys.isDev
      // Valido que el report_item exista
      const report_item: RIMapon | undefined = await dispatch('reports/getReportItemByID', { ri_wialon_id: alert.unit_id }, { root: true })
      if (!report_item) {
        if (isDev) console.log(`Unidad ${alert.unit_id} no en consola`)
        return
      }

      // Valido que el grupo tenga notificaciones activas
      const isGroupNotificationsActive: boolean = await dispatch('console/isSubscribedToGroup', report_item.id_group, { root: true })
      if (!isGroupNotificationsActive) {
        if (isDev) console.log(`El grupo ${report_item.id_group} no tiene notificaciones activas`)
          return
      }

      // Valido que se allan podido obtener los datos de la unidad
      const unit = await MaponSDK.actions.AUnit.getById(alert.unit_id)
      if (!unit) {
        if (isDev) console.log(`Unidad ${report_item.ri_unit} no encontrada`)
        return
      }

      if (unit) {
        const notification: ResourceNotification<AUnit> = {
          color: '#28affc',
          pType: PLATAFORM_TYPE.MAPON,
          blink: 0,
          f: 0,
          name: alert.alert_type,
          // @ts-ignore
          nid: alert.alert_type,
          p: {
            x: unit.lat,
            y: unit.lng
          },
          rt: 0,
          t: Date.now() / 1000,
          x: unit.lat,
          y: unit.lng,
          unitData: unit,
          unit: unit.unit_id,
          tp: '',
          txt: alert.msg,
          url: ''

        }
        dispatch('console/addNotification', notification, { root: true })
      }
    }
    if (state) {
      MaponSDK.actions.AAlert.addEventListener('ALERT_MESSAGE_CHANGED', callback)
      dispatch('sys/showNotificationMessage', {
        title: 'Notificaciones de mapon activadas',
        color: 'success'
      }, { root: true })
    } else {
      MaponSDK.actions.AAlert.removeEventListener('ALERT_MESSAGE_CHANGED', callback)
      dispatch('sys/showNotificationMessage', {
        title: 'Notificaciones de mapon desactivadas',
        color: 'success'
      }, { root: true })
    }
  },
  async createReportItemByPlataformID (context: ActionContext<State, string>, data: { id: any; pType: PLATAFORM_TYPE }): Promise<void> {
    // @ts-ignore
    const unitItem = await MaponSDK.actions.AUnit.getById(data.id, { include: ['ignition', 'drivers'], raw: true })
    if (unitItem) {
      // @ts-ignore
      const reportItem = new RIMapon({}, { unitItem, type: data.pType })
      // @ts-ignore
      return reportItem
    }
  },
  async getUnits (context: ActionContext<State, string>) {
    // @ts-ignore
    const units = await trycatch(() => MaponSDK.actions.AUnit.list({}, { raw: true }), []);
    // @ts-ignore
    return units.map(obj => ({ ...obj, id: `${obj.unit_id}`, name: obj.number, consoleUnit: context.rootState.manager.admin.units.find(unit => `${unit.ri_wialon_id}` === `${obj.unit_id}`) }))
  },
  async getMaponGeofenceswithCoordsByIds ({ rootState, state }: ActionContext<State, string>, { selectedGeofencesIds }: { selectedGeofencesIds: number[] }) {
    // @ts-ignore
    const avaliableMaponGeofences: ResourceGeofences | undefined = rootState.geofences.geofences.find(rGeofence => rGeofence.pType === PLATAFORM_TYPE.MAPON)
    if (!avaliableMaponGeofences) return []

    const setSelectedGeofencesIds = new Set(selectedGeofencesIds)

    const maponGeofencesWithPoints: Geofence[] = avaliableMaponGeofences.geofences.reduce((prev, curr) => {
      if (!setSelectedGeofencesIds.has(curr.id)) return prev
      const object = state.objects.find(obj => obj.id === curr.id)
      if (!object) return prev
      const currGeofeceData: Geofence = {
        ...curr,
        // @ts-ignore
        p: object.getPoints().map(arr => ({ lat: arr.at(0), lng: arr.at(1) })),
        pType: PLATAFORM_TYPE.MAPON
      }
      prev.push(currGeofeceData)
      return prev
    }, [] as Geofence[])

    return maponGeofencesWithPoints
  }
}
