import store from '@/store'
import Driver from '@/store/interfaces/Driver'
import { ReportItem } from '@/store/interfaces/ReportModules'
import { RouteDestination } from '@/store/modules/geofences'
import AObject from '@/submodules/maponjssdk/dist/classes/actions/AObjects.action'
import AUnit from '@/submodules/maponjssdk/dist/classes/actions/AUnit.action'
import { Unit } from '@/submodules/maponjssdk/dist/interfaces/unit.interface'
import osmGetAddress from '@/utils/osmGetAddress'
import { DateTime } from 'luxon'
import ReportItemData, { ReportItemProperties } from './ReportItemData'
import { REPORT_ITEM_IGNITION_STATE } from '@/store/catalogs/REPORT_ITEM_IGNITION_STATE'

export default class RIMapon extends ReportItemData<AUnit, Unit>
  implements ReportItem {
  public bateryFlag: string

  constructor (reportItem: ReportItem, properties: ReportItemProperties<any>, options = { initObservers: true }) {
    super(reportItem, properties, options)
    this.bateryFlag = 'V'
  }

  getUnitName () {
    return this.unit_item?.getName() || this.ri_unit
  }

  unsusbscribeToUnitMessages (): void {
    this.unit_item.removeEventListener('MESSAGE_CHANGED', data => {
      this.last_message = { ...data, pos: { x: data.lat, y: data.lng, c: data.direction } }
      this.onUnitDataChanged()
    })
  }

  susbscribeToUnitMessages () {
    // @ts-ignore
    this.last_message = { ...this.unit_item.lastData, pos: { x: this.unit_item.lastData?.lng, y: this.unit_item.lastData?.lat, c: this.unit_item.lastData?.direction } }
    try {
      this.unit_item.addEventListener('MESSAGE_CHANGED', data => {
        this.last_message = { ...data, pos: { x: data.lng, y: data.lat, c: data.direction } }
        this.onUnitDataChanged()
      })
    } catch (error) {
      console.error(error)
    }
  }

  getLastMessageTimeInMillisecons () {
    const millis = DateTime.fromISO(this.last_message.last_update).toMillis()
    return (millis / 1000)
  }

  async getUbication (): Promise<string> {
    const ubication = await osmGetAddress(this.unit_item.lat, this.unit_item.lng)
    return ubication?.display_name || ''
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getCurrPlataformGeofencesIds (_: { y: any; x: any }): Promise<number[]> {
    const routesDestinationsGeofences: {
      id_group: number;
      id_plataform: number;
      route_destinations: Array<RouteDestination>;
      // @ts-ignore
    }[] = store.state.geofences.routesDestinationsGeofences.filter(
      // @ts-ignore
      rd => rd.id_plataform === this.ri_type
    )
    const destinations_ids = routesDestinationsGeofences.reduce((prev, curr) => {
      // @ts-ignore
      const ids = curr.route_destinations.map(rd => rd.rd_geofences.map(rg => rg.id)).flat()
      // @ts-ignore
      return prev.concat(ids)
    }, [])
    const geofences_ids = this.last_data.DESTINATION.geofences.map(g => g.id)

    const geofencesIds = [...new Set([...destinations_ids, ...geofences_ids])]

    return geofencesIds
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async isOnZone (coors: { y: any; x: any }): Promise<boolean> {
    const isOnConsoleGeofences = this.isOnZoneConsoleGeofences(coors);
    if (isOnConsoleGeofences) return true

    const geofencesIds = await this.getCurrPlataformGeofencesIds(coors)

    const objects: AObject[] = await store.dispatch('mapon/getObjectsByIds', geofencesIds)
    for (let i = 0; i < objects.length; i++) {
      const obj = objects[i]
      const isOnPoint = obj.isPointInside({ lat: coors.x, lng: coors.y })
      if (isOnPoint) {
        return isOnPoint
      }
    }

    return false
  }

  public initLocationObserver () {
    // @ts-ignore
    if (this.location.keyListener) return
    const lastData = this.unit_item.lastData
    // @ts-ignore
    this.last_message = { ...lastData, pos: { x: lastData?.lng, y: lastData?.lat, c: lastData?.direction } }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.unit_item.addEventListener('POSITION_CHANGED', async data => {
      if (!this.location.keyListener) return
      await this.makeLocationRequest({ x: data.lat, y: data.lng })
    })

    this.location.keyListener = 'SUBSCRIBED'
    // @ts-ignore
    this.makeLocationRequest({ x: this.unit_item.lat, y: this.unit_item.lng })
  }

  stopLocationObserver () {
    this.unit_item.removeEventListener('POSITION_CHANGED', async data => {
      if (!this.location.keyListener) return
      await this.makeLocationRequest({ x: data.lat, y: data.lng })
    })
    this.location.onZone = true
    this.location.keyListener = ''
  }

  getIcon (): string {
    return require('@/assets/svg/s_a_35.svg')
  }

  async getIgnitionState (): Promise<REPORT_ITEM_IGNITION_STATE> {
    if (this.unit_item.ignition) {
      if (this.unit_item.ignition.value === 'off') {
        return 0
      } else if (this.unit_item.ignition.value === 'on') {
        return 1
      } else {
        return 3
      }
    }
    return 3
  }

  getCoordinates (): string {
    return this.unit_item.getCoordinates
  }

  async getLinkCamera () {
    return ''
  }

  async getHistoryVideo () {
    return ''
  }

  speed (): number {
    return this.unit_item?.speed || 0
  }

  stoppedTime (): string {
    return ''
}

  getGoogleLink (): string {
    return this.unit_item.googleLink
  }

  getCurrentCoordinatesRaw (): { x: any; y: any } {
    return this.unit_item.getCoordinatesRaw
  }

  getResDrivers (): Driver[] {
    const drvs = this.unit_item.getDrivers()
    // @ts-ignore
    const driversFormated: Driver[] = drvs.map(drv => ({
      n: drv.name,
      id: drv.id,
      p: drv.phone
    }))

    return driversFormated
  }

  public async getSensors () {
    return [
      {
        name: 'Ignición',
        result: this.unit_item.ignition?.value || ''
      },
      {
        name: 'Batería GPS',
        result: this.unit_item.state?.debug_info?.data?.last_voltage
      },
      {
        name: 'Sensor de Velocidad',
        result: this.unit_item.speed?.toString() || ''
      },
      {
        name: 'Estado del vehiculos',
        result: this.unit_item.state?.name
      },
      {
        name: 'Kilometraje',
        result: this.unit_item.mileage?.toString()
      },
      {
        name: 'Odometro',
        result: this.unit_item.direction?.toString()
      }
    ]
  }

  getCommandList () {
    return []
  }

  async executeCommand (payload: any) {
    // --
  }

  batteryGPSLevel (): number {
    return -1
  }

  batteryUnitLevel (): number {
    return -1
  }

  async automaticReport () {
    // --
  }

  initAutomaticReportObserver (): void {
    // --
  }

  stopAutomaticReportObserver (): void {
    // --
  }
}
