import { ResourceGeofences, RouteDestination } from '@/store/interfaces/Geofences'

const state = {
  geofences: [] as ResourceGeofences[],
  geofencesCrud: {
    modal: false,
    id_group: 0
  },
  routesDestinationsGeofences: [] as { id_group: number; id_plataform: number; route_destinations: RouteDestination[] }[],
  editRoute: {
    isEditRouteDestination: false,
    routeDestination: {
      id_route_destinations: 0,
      rd_geofences: [],
      rd_name: ''
    } as RouteDestination
  }
}

export type GeofencesState = typeof state

export type State = typeof state

export default state
