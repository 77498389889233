import { IRoute } from '@/store/interfaces/Route'
import { State } from './state'
import { IMarker } from '@/store/interfaces/Marker'
import Vue from 'vue'
import { Geofence } from '@/store/interfaces/Geofences'

/**
 * Type definition for adding unit analytics state data.
 * Represents the structure expected for the payload in the ADD_UNIT_ANALITICS_STATE_DATA mutation.
 */
export type IAddUnitAnaliticsItem = { data: State['unitsAnaliticsData'][keyof State['unitsAnaliticsData']], key: string }

export default {
  SET_STATE (state: State, payload: typeof state) {
    Object.assign(state, payload)
  },
  ADD_UNIT_ANALITICS_ITEM (state: State, payload: IAddUnitAnaliticsItem) {
    Vue.set(state.unitsAnaliticsData, payload.key, payload.data)
  },
  SET_UNIT_ANALITICS_ITEM_STATE (state: State, payload: { key: string, data: State['unitsAnaliticsData'][keyof State['unitsAnaliticsData']] }) {
    Object.assign(state.unitsAnaliticsData[payload.key], payload.data)
  },
  REMOVE_UNIT_ANALITICS_ITEM (state: State, key: string) {
    Vue.delete(state.unitsAnaliticsData, key)
  },
  SET_MAP_REF (state: State, $ref: any) {
    state.$ref = $ref
  },
  CLEAN_ALL (state: State) {
    state.selectedGeofences = []
    state.selectedRouteGeofences = []
    state.selectedMarkers = []
    state.selectedRoutes = []
    Vue.set(state, 'unitsAnaliticsData', {})
  },
  UPDATE_OBJS_KEY (state: State) {
    state.mapObjsKey++
  },
  DELETE_ROUTE (state: State, index: number) {
    state.selectedRoutes.splice(index, 1)
  },
  SET_ROUTES (state: State, routes: IRoute[]) {
    state.selectedRoutes = routes
  },
  SET_MARKERS (state: State, markers: IMarker[]) {
    state.selectedMarkers = markers
  },
  ADD_GEOFENCES (state: State, geofences: Geofence[]) {
    state.selectedGeofences.push(...geofences)
  },
  PUSH_MARKER (state: State, marker: IMarker) {
    state.selectedMarkers.push(marker)
  },
  PUSH_ROUTES (state: State, route: IRoute) {
    state.selectedRoutes.push(route)
  }
}
